<template>
  <div class="grg_page_nuestra_gente">
    <section class="grg_section_principal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-0 grg_container_principal">
            <div class="w-100">
              <img
                class="img-fluid grg_transparencia"
                width="100%"
                src="@/assets/img/nuestra_gente/portada.webp"
                alt=""
              />
              <div class="grg_container_trasparencia"></div>
            </div>
            <div class="grg_container_titulo_pagina text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_big
                  text-uppercase
                "
                style="
                  --color_solid: #fff;
                  --color_outline: #fff;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Nuestra Gente
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_descripcion">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div style="padding: 2rem 0 0">
              <p class="grg_text_medium">
                <strong
                  ><span
                    class="
                      grg_font_outline
                      grg_first_letter
                      grg_font_family_reb
                      grg_title_bigger
                      text-uppercase
                    "
                    style="
                      --color_solid: #000;
                      --color_outline: #000;
                      --pos_x: 0.05em;
                      --pos_y: 0.05em;
                      line-height: 0.5;
                    "
                    >E</span
                  >n GRG<span style="font-size: 10px">®</span> priorizamos el
                  bienestar profesional y personal de todos nuestros
                  colaboradores.</strong
                >
                Somos un grupo restaurantero que tiene como objetivo buscar el
                desarrollo profesional de nuestro equipo. Al ser una empresa con
                un gran sentido humano, constantemente estamos trabajando para
                que todas las personas que forman parte de nuestra familia
                puedan tener un crecimiento dentro de la empresa. Para
                lograrlo,<strong>
                  ofrecemos todo tipo de herramientas que sumen al impulso de
                  todos nuestros colaboradores.</strong
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_adn">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div style="padding: 0 0 2rem 0">
              <div class="grg_container_adn">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                  "
                >
                  <h1
                    class="
                      grg_font_outline grg_font_family_rb grg_title_medium
                      text-uppercase
                    "
                    style="
                      --color_solid: #000;
                      --color_outline: #000;
                      --pos_x: 0.05em;
                      --pos_y: -0.05em;
                      margin-right: 5%;
                    "
                  >
                    ADN
                  </h1>
                  <img
                    class="img-fluid grg_image_logo_adn"
                    src="@/assets/img/logos/logo_numeralia.svg"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <p class="grg_text_medium grg_font_family_rr">
                  Los valores y principios de actuación de GRG<span
                    style="font-size: 10px"
                    >®</span
                  >
                  son pieza fundamental en la identidad de la empresa.
                  Todas las
                  personas de nuestro equipo cuentan con características,
                  habilidades y valores que forman el ADN de GRG<span
                    style="font-size: 10px"
                    >®</span
                  >. Gracias a esto, <strong>hemos conformado una cultura organizacional con el objetivo final de alcanzar y superar nuestras metas.</strong>
                </p>
                
                <p class="grg_text_medium grg_font_family_rr text-center">
                  <strong>Nuestro ADN está compuesto por 4 pilares:</strong>
                </p>
              </div>
              <div>
                <div class="container-fluid">
                  <div class="row">
                    <div
                      class="
                        col-sm-12 col-md-12 col-lg-4 col-xl-4
                        grg_pilares_derecha
                      "
                    >
                      <div>
                        <p
                          class="
                            text-center
                            grg_text_small grg_font_family_reb grg_font_outline
                            text-uppercase
                          "
                          style="
                            --color_solid: #000;
                            --color_outline: #000;
                            --pos_x: 0em;
                            --pos_y: 0em;
                            color: #000;
                          "
                        >
                          <strong>Experiencia al cliente</strong>
                        </p>
                        <p class="grg_text_extra_small grg_font_family_rr">
                          Ofrecemos un servicio y experiencia insuperables, <strong>
                            nos distinguimos por satisfacer todas las
                            necesidades de nuestros clientes.
                          </strong>
                          Somos un grupo semillero de innovación: transformamos ideas, creamos conceptos y mejoramos nuestros procesos constantemente para garantizar una gran experiencia.
                        </p>
                      </div>
                      <div>
                        <p
                          class="
                            text-center
                            grg_text_small grg_font_family_reb grg_font_outline
                            text-uppercase
                          "
                          style="
                            --color_solid: #000;
                            --color_outline: #000;
                            --pos_x: 0em;
                            --pos_y: 0em;
                            color: #000;
                          "
                        >
                          <strong>Liderazgo efectivo</strong>
                        </p>
                        <p class="grg_text_extra_small grg_font_family_rr">
                          Impulsamos la visión y el propósito en todas las áreas; promovemos la importancia del compromiso, creamos entornos de trabajo positivos y motivadores. 
                          <strong
                            >Todos los días nos encargamos de desarrollar a
                            nuestro talento.</strong
                          >
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center"
                    >
                      <img
                        class="img-fluid grg_imagen_adn_pilares"
                        width="80%"
                        src="@/assets/img/nuestra_gente/adn.webp"
                        alt=""
                      />
                    </div>
                    <div
                      class="
                        col-sm-12 col-md-12 col-lg-4 col-xl-4
                        grg_pilares_izquierda
                      "
                    >
                      <div>
                        <p
                          class="
                            text-center
                            grg_text_small grg_font_family_reb grg_font_outline
                            text-uppercase
                          "
                          style="
                            --color_solid: #000;
                            --color_outline: #000;
                            --pos_x: 0em;
                            --pos_y: 0em;
                            color: #000;
                          "
                        >
                          <strong
                            >Compromiso con<br />
                            los resultados y proactividad</strong
                          >
                        </p>
                        <p class="grg_text_extra_small grg_font_family_rr">
                          Somos eficaces y eficientes. Nos distinguimos por el enfoque estratégico, alta calidad de las decisiones, compromiso con los resultados y la iniciativa para enfrentar las oportunidades y retos.
                          <strong>Hacemos que las cosas sucedan.</strong>
                        </p>
                      </div>
                      <div>
                        <p
                          class="
                            text-center
                            grg_text_small grg_font_family_reb grg_font_outline
                            text-uppercase
                          "
                          style="
                            --color_solid: #000;
                            --color_outline: #000;
                            --pos_x: 0em;
                            --pos_y: 0em;
                            color: #000;
                          "
                        >
                          <strong>Compromiso Organizacional y Social</strong>
                        </p>
                        <p class="grg_text_extra_small grg_font_family_rr">
                          Generamos confianza mediante la honestidad, integridad y autenticidad. Somos adaptables y tenemos un gran sentido de responsabilidad social,
                          <strong
                            >nuestras decisiones están fundamentadas en el
                            mejoramiento social y ambiental de las comunidades
                            con las que interactuamos</strong
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_instituto_grg">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div
              class="grg_container_titulo text-center"
              style="padding: 2rem 0 2rem 0"
            >
              <h1
                class="
                  grg_font_outline grg_font_family_reb grg_title_medium
                  text-uppercase
                "
                style="
                  --color_solid: #000;
                  --color_outline: #000;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                <img
                  src="@/assets/img/nuestra_gente/logoNG.webp"
                  width="190px"
                  alt=""
                  style="margin-left: 0px;"
                /><!--INSTITUTO GRG--><span
                  class="grg_text_small grg_font_outline"
                  style="
                    --color_solid: #000;
                    --color_outline: #000;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                  "
                  ></span
                >
              </h1>
            </div>
            <div>
              <p class="grg_text_medium grg_font_family_rr">
                <strong
                  ><span
                    class="
                      grg_font_outline
                      grg_first_letter
                      grg_font_family_reb
                      grg_title_bigger
                      text-uppercase
                    "
                    style="
                      --color_solid: #000;
                      --color_outline: #000;
                      --pos_x: 0.05em;
                      --pos_y: 0.05em;
                      line-height: 0.5;
                    "
                    >N</span
                  >uestros colaboradores y su desarrollo son de suma importancia
                  para nosotros.</strong
                >
                Con el objetivo de proveerles herramientas técnicas y
                administrativas, fomentando su crecimiento como personas y
                profesionistas, creamos el Instituto GRG<span
                  style="font-size: 10px"
                  >®</span
                >. El Instituto tiene una amplia oferta académica. Nuestros
                colaboradores tienen la posibilidad de graduarse de primaria,
                secundaria, bachillerato y de la licenciatura en línea
                “Desarrollo Gerencial con Acentuación en Administración de
                Restaurantes” (con reconocimiento oficial) o de tomar diferentes
                cursos y diplomados.
              </p>
              <p class="grg_text_medium grg_font_family_rr">
                <strong
                  >Nuestra oferta educativa se compone de más de 56 programas,
                  cursos y talleres</strong
                >
                con diferentes ejes temáticos relacionados con capacitación
                técnica y operativa, cultura organizacional, servicio,
                liderazgo, educación formal y desarrollo humano, entre otros. Es
                un gran privilegio contar con la participación de este
                extraordinario programa académico que nos permite
                <strong
                  >impactar de manera directa en el bienestar de nuestros
                  colaboradores y sus familias.
                </strong>
              </p>
              <p class="grg_text_medium grg_font_family_rr">
                El promedio anual de formación que se imparte desde el Instituto
                GRG<span style="font-size: 10px">®</span> alcanza
                <strong>40,000 asistentes a cursos</strong>, lo que representa
                que al año un colaborador toma de 3 a 5 cursos. Del 2012 a la
                fecha han elevado su nivel educativo 522 colaboradores:
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_numeralia_nuestra_gente">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div
              class="grg_container_numeralia_cedis text-center"
              style="padding: 2rem 0 2rem 0"
            >
              <div
                v-for="numeralia of numeralias"
                :key="numeralia.id"
                class="col-6 col-sm-5 col-md-3 col-lg-3 col-xl-3"
              >
                <div
                  class="grg_container_letra_text"
                  style="flex-direction: column"
                >
                  <div>
                    <div class="grg_container_image_icon_valor">
                      <img
                        class="img-fluid"
                        width="100px"
                        :src="obtenerIconosNumeralia(numeralia.icono)"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      class="grg_font_family_reb text-uppercase numeralia"
                      v-html="numeralia.nombre"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_boton_fixed">
      <div
        class="container-fluid"
        style="background-color: rgba(255, 255, 255, 0.97)"
      >
        <div class="row m-0">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="text-center">
              <h1
                class="text-uppercase grg_font_family_rb grg_title_extra_small"
                style="
                  color: #383735;
                  display: flex;
                  justify-content: center;
                  align-items: baseline;
                "
              >
                ¿Quieres ser parte de
                <img
                  class="img-fluid grg_image_boton_fixed"
                  src="@/assets/img/logos/logo_numeralia.svg"
                  width="125px"
                  alt=""
                  style="margin-left: 10px; filter: brightness(0.2)"
                />?
              </h1>
            </div>

            <div class="grg_container_boton_fixed">
              <router-link to="/vacantes"
                ><h1
                  id="grg_btn_reclutamiento_fixed"
                  class="
                    grg_btn_reclutamiento
                    grg_container_color
                    grg_font_family_rb
                    grg_text_medium
                    text-white text-uppercase
                  "
                  style="
                    --background_color: #bd203b;
                    justify-content: center;
                    display: flex;
                    align-items: baseline;
                  "
                >
                  Ver vacantes
                </h1></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "NuestraGente",
  components: {},
  data() {
    return {
      numeralias: [
        {
          id: 1,
          nombre:
            '<h1 class="grg_title_extra_big " style="line-height: 1;">54</h1><h1 class="grg_text_extra_small">Graduados de licenciatura</h1>',
          icono: "licenciatura.svg",
          icono_width: "100px",
        },
        {
          id: 2,
          nombre:
            '<h1 class="grg_title_extra_big " style="line-height: 1;">392</h1><h1 class="grg_text_extra_small">Graduados de preparatoria</h1>',
          icono: "preparatoria.svg",
          icono_width: "100px",
        },
        {
          id: 3,
          nombre:
            '<h1 class="grg_title_extra_big" style="line-height: 1;">65</h1><h1 class="grg_text_extra_small">Graduados de secundaria</h1>',
          icono: "secundaria.svg",
          icono_width: "100px",
        },
        {
          id: 4,
          nombre:
            '<h1 class="grg_title_extra_big " style="line-height: 1;">11</h1><h1 class="grg_text_extra_small">Graduados de primaria y lectoescritura</h1>',
          icono: "primaria.svg",
          icono_width: "100px",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
    obtenerIconosNumeralia(icono) {
      if (icono != "") {
        return require("@/assets/img/nuestra_gente/" + icono);
      }
    },
    detectarScrollBotonFixed(evt, el) {
      if (
        $(window).scrollTop() >
        $(".grg_componente_footer").offset().top -
          $(window).height() -
          $(".grg_componente_footer").height()
      ) {
        $(".grg_section_boton_fixed").css("position", "relative");
        $(".grg_section_boton_fixed").css("width", "calc(100%)");
      }
      if (
        $(window).scrollTop() <
        $(".grg_componente_footer").offset().top -
          $(window).height() -
          $(".grg_componente_footer").height()
      ) {
        $(".grg_section_boton_fixed").css("position", "fixed");
        $(".grg_section_boton_fixed").css("width", "calc(100% - 50px)");
      }
    },
  },
  created() {
    this.grgFuncionChangeTransparencia(true);
    window.addEventListener("scroll", this.detectarScrollBotonFixed);
  },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `GRG | Nuestra Gente`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GrupoToks",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.grg_section_boton_fixed .grg_title_extra_small {
  font-size: calc(2rem + 1.5vw + -20px);
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */
.grg_transparencia {
  filter: brightness(0.5);
}
.grg_container_trasparencia {
  background: linear-gradient(
    180deg,
    #0000 0%,
    #0000003b 24%,
    #000000e3 76%,
    #000,
    #000,
    #000
  );
  position: absolute;
  bottom: 0;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s linear;
  z-index: 2;
}
.grg_container_principal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_container_titulo_pagina {
  position: absolute;
  margin: 1% 0;
  bottom: 0;
  z-index: 10;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>< Estilo para seccion descripcion */
.grg_container_numeralia_cedis {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.grg_container_letra_text {
  display: flex;
  justify-content: center;
}
.grg_container_numeralia_cedis > div {
  padding: 20px;
}
.numeralia h1 {
  background-color: pink;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>< Estilo para seccion descripcion */

.grg_container_adn {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 1rem;
}
.grg_container_adn > div {
  margin: 0 2rem;
}
.grg_image_logo_adn {
  filter: brightness(0);
  /* width: 15%; */
  width: calc(50px + 12vw + 20px);
}
.grg_btn_reclutamiento {
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  max-width: initial;
}
.grg_btn_reclutamiento:hover{
  background-color: #2b2b2b;
}
.grg_container_boton {
  display: flex;
  justify-content: center;
  width: 100%;
}
.grg_container_boton_fixed {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.grg_section_boton_fixed {
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: calc(100% - 50px);
}

@media (min-width: 1200px) {
}
@media (min-width: 992px) {
  .grg_section_principal {
    margin-top: -95px;
  }
  .grg_pilares_derecha {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .grg_pilares_izquierda {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transform: translateY(15%);
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion descripcion */
  .grg_container_numeralia_cedis {
    width: 90%;
    margin: 0 auto;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion descripcion */
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
  .grg_imagen_adn_pilares {
    width: 200px;
  }
}
@media (max-width: 767.98px) {
  /* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion ADN */
  .grg_container_image_and {
    text-align: center;
    padding: 10px 0;
  }
  .grg_container_image_and img {
    width: 40%;
  }
  .grg_imagen_adn_pilares {
    width: 150px;
  }
  /* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion ADN */
  .grg_section_boton_fixed {
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: calc(100%) !important;
  }
}
@media (max-width: 575.98px) {
  .grg_imagen_adn_pilares {
    width: 30%;
  }
  .grg_image_boton_fixed {
    width: 80px;
  }
}
</style>