<template>
  <div class="grg_page_noticias">
    <!-- <section class="grg_section_principal"  style="background: linear-gradient(180deg, #00000066, white);">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center" >
                        <div style="display: flex;justify-content: center;align-items: baseline;">
                            <h1 class="grg_font_outline grg_font_family_rb grg_title_medium text-uppercase mr-4" style="--color_solid:#000; --color_outline:#000; --pos_x: 0.05em; --pos_y: -0.05em;">Noticias</h1>
                            <img class="img-fluid grg_image_grg_noticias"  src="@/assets/img/logos/logo_numeralia.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

    <section v-show="slider_noticias_prioridad_1.length != 0">
      <div class="container grg_container_noticias_prioridad_1">
        <div class="row">
          <div class="col-md-12">
            <div id="carrucelBlog" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner" style="border-radius: 10px">
                <div
                  v-for="slider of slider_noticias_prioridad_1"
                  :key="slider.id"
                  class="carousel-item"
                  :class="{ active: slider.posicion == 0 }"
                >
                  <b-card
                    :title="slider.titulo"
                    :img-src="obtenerImagenesBlog(slider.imagen)"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2 grg_noticias_post_principales"
                  >
                    <b-card-text>
                      <span class="grg_font_family_rb">
                        {{ slider.descripcion }}
                      </span>
                    </b-card-text>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        padding: 0.3rem 2.5rem;
                      "
                    >
                      <router-link
                        v-if="typeof slider.id !== 'undefined'"
                        class="botom_custom grg_text_medium grg_font_family_rb"
                        :to="{
                          name: 'Noticia',
                          params: { nombre_entrada: slider.nombre_entrada},
                        }"
                        >Ver más</router-link
                      >
                    </div>
                  </b-card>
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carrucelBlog"
                role="button"
                data-slide="prev"
              >
                <span aria-hidden="true"
                  ><img
                    class="grg_elemento_flechas_slider"
                    width="40%"
                    src="@/assets/img/utilerias/flecha_izquierda.webp"
                    alt=""
                /></span>
                <span class="sr-only">Anterior</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carrucelBlog"
                role="button"
                data-slide="next"
              >
                <span aria-hidden="true">
                  <img
                    class="grg_elemento_flechas_slider"
                    width="40%"
                    src="@/assets/img/utilerias/flecha_derecha.webp"
                    alt=""
                /></span>
                <span class="sr-only">Siguiente</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-show="slider_noticias_prioridad_2.length != 0">
      <div
        class="container grg_container_noticias_prioridad_2"
        style="padding: 20px 15px"
      >
        <div class="row">
          <div
            v-show="slider.id == 1 || slider.id == 2"
            class="col-sm-12 col-md-6 col-lg-6"
            v-for="(slider, index) of slider_noticias_prioridad_2"
            :key="index"
          >
            <b-card
              :title="slider.titulo"
              :img-src="obtenerImagenesBlog(slider.imagen)"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2 grg_article_card_noticia"
            >
              <b-card-text>{{ slider.descripcion }}</b-card-text>
              <div style="display: flex; justify-content: center">
                <router-link
                  v-if="typeof slider.id !== 'undefined'"
                  class="botom_custom grg_text_extra_small grg_font_family_rb"
                  :to="{ name: 'Noticia', params: { nombre_entrada: slider.nombre_entrada}, }"
                  >Ver más</router-link
                >
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </section>

    <section v-show="slider_noticias_prioridad_3.length != 0">
      <div
        class="container grg_container_noticias_prioridad_3"
        style="padding: 20px 15px"
      >
        <div class="row">
          <div
            class="col-sm-12 col-md-6 col-lg-4 col-xl-4"
            v-for="(slider, index) of shownCards"
            :key="index"
          >
            <b-card
              :title="slider.titulo"
              :img-src="obtenerImagenesBlog(slider.imagen)"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2 grg_article_card_noticia"
            >
              <b-card-text>
                {{ slider.descripcion }}
              </b-card-text>
              <div style="display: flex; justify-content: center">
                <router-link
                  v-if="typeof slider.id !== 'undefined'"
                  class="botom_custom grg_text_extra_small grg_font_family_rb"
                  :to="{ name: 'Noticia', params: { nombre_entrada: slider.nombre_entrada}, }"
                  >Ver más
                </router-link>
              </div>
            </b-card>
          </div>
        </div>
        <div class="text-center">
          <v-pagination
            class="grg_container_componente_paginacion"
            v-model="page"
            :length="length"
            circle
            light
            prev-icon="mdi-chevron-double-left"
            next-icon="mdi-chevron-double-right"
          ></v-pagination>
        </div>
      </div>
    </section>

    <!-- <v-container>
          <v-col cols="12" xs="12" sm="12" xl="12" md="12" lg="12">
            <v-row>
              <v-col v-for="c in shownCards" :key="c.num" cols="12" xs="12" sm="12" xl="3" md="12" lg="3">
                <v-card>
                  <v-card-title>{{c.num}}</v-card-title>
                  <v-card-subtitle>{{c.nombre}}</v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <div class="text-center">
                <v-pagination v-model="page" :length="length"></v-pagination>
              </div>
            </v-row>
          </v-col>
        </v-container>
        {{shownCards}} -->

    <!-- <div class="text-center">
      <div class="container">{{visiblePages}}</div>
      <v-pagination
        v-model="page"
        :length="Math.ceil(slider_noticias_prioridad_3.length/perPage)"
      ></v-pagination>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Noticias",
  data() {
    return {
      slider_noticias_prioridad_1: [{}],
      slider_noticias_prioridad_2: [{}],
      slider_noticias_prioridad_3: [{}],
      page1: 1,
      perPage1: 6,
      pages1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],

      page: 1,
      cards: [
        { num: 1, nombre: "n1" },
        { num: 2, nombre: "n2" },
        { num: 3, nombre: "n3" },
        { num: 4, nombre: "n4" },
        { num: 5, nombre: "n5" },
        { num: 6, nombre: "n6" },
        { num: 7, nombre: "n7" },
        { num: 8, nombre: "n8" },
      ],
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
    obtenerImagenesBlog(imagen) {
      if (imagen != "" && imagen != null) {
        return this.$baseURL + imagen;
      }
    },
    get_entradas_public_1() {
      const baseURI =
        this.$baseURL + "grg-admin/index.php/entrada/get_entradas_public_1";
      this.$http
        .get(
          baseURI,
          { params: { token: localStorage.getItem("token") } },
          { "Access-Control-Allow-Origin": "*" }
        )
        .then((res) => {
          var index = 0;
          // console.log(res.data.NoticiasPrioridad1);
          res.data.noticias.forEach((element) => {
            element.posicion = index;
            index++;
            element.id = index;
          });
           console.log('-----------');
           console.log(res.data.noticias);
          this.slider_noticias_prioridad_1 = res.data.noticias;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_entradas_public_2() {
      const baseURI =
        this.$baseURL + "grg-admin/index.php/entrada/get_entradas_public_2";
      this.$http
        .get(
          baseURI,
          { params: { token: localStorage.getItem("token") } },
          { "Access-Control-Allow-Origin": "*" }
        )
        .then((res) => {
          var index = 0;
          // console.log(res.data.NoticiasPrioridad1);
          res.data.noticias.forEach((element) => {
            element.posicion = index;
            index++;
            element.id = index;
          });
          //  console.log(res.data.noticias);
          this.slider_noticias_prioridad_2 = res.data.noticias;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    get_entradas_public_3() {
      const baseURI =
        this.$baseURL + "grg-admin/index.php/entrada/get_entradas_public_3";
      this.$http
        .get(
          baseURI,
          { params: { token: localStorage.getItem("token") } },
          { "Access-Control-Allow-Origin": "*" }
        )
        .then((res) => {
          var index = 0;
          // console.log(res.data.NoticiasPrioridad1);
          res.data.noticias.forEach((element) => {
            element.posicion = index;
            index++;
            element.id = index;
          });
          //  console.log(res.data.noticias);
          this.slider_noticias_prioridad_3 = res.data.noticias;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.get_entradas_public_1();
    this.get_entradas_public_2();
    this.get_entradas_public_3();
  },
  created() {
    this.grgFuncionChangeTransparencia(false);
  },
  computed: {
    visiblePages() {
      var entrada = [];
      entrada = this.slider_noticias_prioridad_3.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
      var entradas = "";
      entrada.forEach((element) => {
        entradas = entradas + element.titulo;
        // console.log(element.id);
      });
      return entradas;
    },

    shownCards() {
      const { page, length, slider_noticias_prioridad_3 } = this;
      const number = 6; // Numero de elementos a mostrar por página
      return slider_noticias_prioridad_3.slice(
        (page - 1) * number,
        page * number
      );
    },
    length() {
      var paginacion = Math.trunc(this.slider_noticias_prioridad_3.length / 6);
      var residuo = this.slider_noticias_prioridad_3.length % 6;
      var paginacion_real = 0;
      if (residuo > 0) {
        paginacion_real = paginacion + 1;
      } else {
        paginacion_real = paginacion;
      }
      return paginacion_real;
    },
  },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `GRG | Noticias`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GrupoToks",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
</script>


<style>
.grg_container_componente_paginacion {
  padding-top: 20px;
}
.grg_container_componente_paginacion .v-pagination__item {
  box-shadow: 0px 0px 0px;
  font-size: 25px;
  font-family: "Raleway_Bold";
  margin-top: 0px;
}
.grg_container_componente_paginacion .v-pagination__navigation {
  box-shadow: 0px 0px 0px;
}
.theme--light.v-pagination .v-pagination__item--active {
  color: #d71a38 !important;
}
.theme--light.v-pagination .v-pagination__item {
  color: #606261;
}
</style>

<style  scoped>
.row {
    margin: 0 -15px;
}
.grg_image_grg_noticias {
  filter: brightness(0);
  width: calc(60px + 15%);
}
.grg_noticias_post_principales {
  border: none;
}

.grg_noticias_post_principales .card-img-top {
  border-top-left-radius: revert;
  border-top-right-radius: revert;
}
.grg_noticias_post_principales .card-body .card-title {
  font-family: Raleway_Bold;
  color: #5f6160;
  font-size: calc(2rem + 2vw + -10px);
}
.grg_noticias_post_principales .card-body {
  padding: 20px 0;
}
.botom_custom {
  background-color: transparent;
  color: #bd203b;
  border: 1px solid #bd203b;
  border-radius: 50px;
  padding: 0.3rem 2.5rem;
  position: absolute;
  bottom: 2%;
}
.carousel-control-prev,
.carousel-control-next {
  bottom: 25%;
  width: 5%;
  opacity: 1;
}
.grg_article_card_noticia {
  height: 100%;
}
.botom_custom:hover{
  background-color: #bd203b;
  color:#fff;
}

@media (min-width: 1200px) {
}
@media (min-width: 992px) {
  .grg_container_noticias_prioridad_3 .card-title {
    /* min-height: 90px; */
  }
  .grg_container_noticias_prioridad_3 .card-text {
    margin-bottom: 30px;
  }
  .grg_container_noticias_prioridad_2 .card-title {
    /* min-height: 70px; */
  }
  .grg_container_noticias_prioridad_2 .card-text {
    margin-bottom: 30px;
  }
  .grg_container_noticias_prioridad_1 .card-text {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
}
</style>