<template>
  <div class="grg_page_cedis">
    <section class="grg_section_principal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-0 grg_container_principal">
            <div>
              <Slider :sliders="sliders"></Slider>
              <!-- <img class="img-fluid grg_transparencia" width="100%" src="@/assets/img/cedis/portada.png" alt=""> -->
              <div class="grg_container_trasparencia"></div>
            </div>
            <div class="grg_container_titulo_pagina text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_big
                  text-uppercase
                "
                style="
                  --color_solid: #fff;
                  --color_outline: #fff;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Cedis
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="grg_section_construccion">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="grg_container_construccion">
                            <div>
                                <img class="img-fluid grg_img_construccion" src="@/assets/img/utilerias/imagen_construccion.png" alt="">
                            </div>
                            <div class="grg_container_leyenda_construccion">
                                <h1 class="grg_font_family_rb grg_text_big">Estamos construyendo esta sección.<br>¡Espérala pronto!</h1>
                            </div>
                            <div class="grg_container_hover">
                                <router-link to="/"><h2 class="grg_font_family_reb grg_text_medium grg_btn grg_btn_animacion" style="--color: #fff; --background_color:#BD203B" data-text="Regresar al inicio">Regresar al inicio</h2></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

    <section class="grg_section_descripcion">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div style="padding: 2rem 0">
              <p class="grg_text_medium">
                <span
                  class="
                    grg_font_outline
                    grg_first_letter
                    grg_font_family_reb
                    grg_title_bigger
                    text-uppercase
                  "
                  style="
                    --color_solid: #000;
                    --color_outline: #000;
                    --pos_x: 0.05em;
                    --pos_y: 0.05em;
                    line-height: 0.5;
                  "
                  >C</span
                >on la más alta tecnología y el último equipamiento, nuestro
                Centro de Distribución, ubicado en Calzada Azcapotzalco y con
                5,000 m2 construidos, inició operaciones en 2017 con el objetivo
                principal de centralizar la cadena de suministro y garantizar la
                demanda de las marcas de GRG<span style="font-size: 10px"
                  >®</span
                >.
              </p>
              <p class="grg_text_medium">
                Desde entonces,
                <strong>
                  CEDIS ha logrado garantizar la calidad y ofrecer un servicio
                  eficiente mediante una entrega eficaz y oportuna de todos
                  nuestros productos</strong
                >. Gracias a esto, hemos logrado asegurar la mejor experiencia
                gastronómica para nuestros clientes.
              </p>
              <p class="grg_text_medium">
                Contamos con estrictos controles de calidad que, en 2019, nos
                permitieron obtener la certificación TIF otorgada por la
                Secretaría de Agricultura y Desarrollo Rural (SAGARPA).
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_nuestros_valores" style="padding: 0 0 2rem 0">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="container-fluid">
              <div class="row grg_container_numeralia_cedis text-center">
                <div
                  v-for="numeralia of numeralias"
                  :key="numeralia.id"
                  class="col-6 col-sm-5 col-md-4 col-lg-4 col-xl-4"
                >
                  <div
                    class="grg_container_letra_text"
                    style="flex-direction: column"
                  >
                    <div>
                      <div class="grg_container_image_icon_numeralia_cedis">
                        <img
                          class="img-fluid"
                          :width="numeralia.icono_width"
                          :src="obtenerIconosNumeralia(numeralia.icono)"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        class="grg_font_family_reb text-uppercase numeralia"
                        v-html="numeralia.nombre"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Slider from "@/components/Slider.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Cedis",
  components: {
    Slider,
  },
  data() {
    return {
      numeralias: [
        {
          id: 1,
          nombre:
            '<h1 class="grg_title_extra_big m-0 p-0" style="line-height: 1;">13</h1><h1 class="grg_text_bigger">puertas</h1><h1 class="grg_text_extra_small">6 refrigeradas y 7 secas</h1>',
          icono: "numeralia_cedis_1.png",
          icono_width: "70px",
        },
        {
          id: 2,
          nombre:
            '<h1 class="grg_title_extra_big m-0 p-0" style="line-height: 1;">1800</h1><h1 class="grg_text_bigger">posiciones</h1><h1 class="grg_text_extra_small">en área seca de racks</h1>',
          icono: "numeralia_cedis_2.png",
          icono_width: "70px",
        },
        {
          id: 3,
          nombre:
            '<h1 class="grg_title_extra_big m-0 p-0" style="line-height: 1;">300</h1><h1 class="grg_text_bigger">posiciones</h1><h1 class="grg_text_extra_small">en plataforma refrigerada -4°C</h1>',
          icono: "numeralia_cedis_3.png",
          icono_width: "70px",
        },
        {
          id: 4,
          nombre:
            '<h1 class="grg_title_extra_big m-0 p-0" style="line-height: 1;">900</h1><h1 class="grg_text_bigger">posiciones</h1><h1 class="grg_text_extra_small">en cámaras de congelación -20°C</h1>',
          icono: "numeralia_cedis_4.png",
          icono_width: "70px",
        },
        {
          id: 5,
          nombre:
            '<h1 class="grg_text_bigger">certificado</h1><h1 class="grg_title_extra_big m-0 p-0" style="line-height: 1;">TIF</h1><h1 class="grg_text_extra_small">a partir de enero 2019</h1>',
          icono: "numeralia_cedis_5.png",
          icono_width: "70px",
        },
      ],
      sliders: [
        {
          id: 1,
          posicion: 0,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/cedis1.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 2,
          posicion: 1,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/cedis2.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 3,
          posicion: 2,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/cedis3.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 4,
          posicion: 3,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/cedis4.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 5,
          posicion: 4,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/cedis5.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
    obtenerIconosNumeralia(icono) {
      if (icono != "") {
        return require("@/assets/img/utilerias/" + icono);
      }
    },
    activarSlider() {
      $("#grg_slider_nuestras_marcas").carousel();
    },
  },
  created() {
    this.grgFuncionChangeTransparencia(true);
  },
  mounted() {
    this.activarSlider();
  },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `GRG | Cedis`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GrupoToks",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.row {
    margin: 0 -15px;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */
.grg_transparencia {
  filter: brightness(0.4);
}
.grg_container_trasparencia {
  background: linear-gradient(
    180deg,
    #0000 0%,
    #0000003b 24%,
    #000000e3 76%,
    #000,
    #000,
    #000
  );
  position: absolute;
  bottom: 0;
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s linear;
  z-index: 2;
}
.grg_container_principal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_container_titulo_pagina {
  position: absolute;
  margin: 1% 0;
  bottom: 0;
  z-index: 10;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>< Estilo para seccion descripcion */
.grg_container_numeralia_cedis {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.grg_container_letra_text {
  display: flex;
  justify-content: center;
}
.grg_container_numeralia_cedis > div {
  padding: 20px;
}
.numeralia h1 {
  background-color: pink;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>< Estilo para seccion descripcion */

.grg_container_construccion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 0;
}
.grg_img_construccion {
  max-width: 60%;
}
.grg_container_leyenda_construccion {
  padding: 1rem 0;
}

@media (min-width: 1200px) {
}
@media (min-width: 992px) {
  .grg_section_principal {
    margin-top: -95px;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion descripcion */
  .grg_container_numeralia_cedis {
    width: 90%;
    margin: 0 auto;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion descripcion */
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
  .grg_img_construccion {
    max-width: 50%;
  }
}
</style>