<template>
  <div class="grg_page_responsabilidad_social">
    <section class="grg_section_principal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-0 grg_container_principal">
            <div>
              <div class="grg_container_trasparencia"></div>
              <Slider :sliders="sliders"></Slider>
            </div>
            <div class="grg_container_titulo_pagina text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_big
                  text-uppercase
                "
                style="
                  --color_solid: #fff;
                  --color_outline: #fff;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Responsabilidad social
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="grg_section_construccion">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="grg_container_construccion">
                            <div>
                                <img class="img-fluid grg_img_construccion" src="@/assets/img/utilerias/imagen_construccion.png" alt="">
                            </div>
                            <div class="grg_container_leyenda_construccion">
                                <h1 class="grg_font_family_rb grg_text_big">Estamos construyendo esta sección.<br>¡Espérala pronto!</h1>
                            </div>
                            <div class="grg_container_hover">
                                <router-link to="/"><h2 class="grg_font_family_reb grg_text_medium grg_btn grg_btn_animacion" style="--color: #fff; --background_color:#BD203B" data-text="Regresar al inicio">Regresar al inicio</h2></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

    <section class="grg_section_menu_responsabilidad_social">
      <div class="container-fluid">
        <div class="row">
          <div
            class="
              col-sm-12 col-md-12 col-lg-12 col-xl-12
              grg_container_items_menu_responsabilidad_social
            "
          >
            <div class="grg_container_item_menu_responsabilidad_social">
              <h1
                class="text-uppercase grg_text_extra_small grg_font_family_rb"
              >
                <a href="#bienvenidos">Bienvenida</a>
              </h1>
            </div>
            |
            <div class="grg_container_item_menu_responsabilidad_social">
              <h1
                class="text-uppercase grg_text_extra_small grg_font_family_rb"
              >
                <a href="#nuestras_acciones">Nuestras acciones</a>
              </h1>
            </div>
            |
            <div class="grg_container_item_menu_responsabilidad_social">
              <h1
                class="text-uppercase grg_text_extra_small grg_font_family_rb"
              >
                <a href="#onu">ONU</a>
              </h1>
            </div>
            |
            <div class="grg_container_item_menu_responsabilidad_social">
              <h1
                class="text-uppercase grg_text_extra_small grg_font_family_rb"
              >
                <a href="#proyectos_productivos">Proyectos productivos</a>
              </h1>
            </div>
            |
            <div class="grg_container_item_menu_responsabilidad_social">
              <h1
                class="text-uppercase grg_text_extra_small grg_font_family_rb"
              >
                <a href="#medio_ambiente">Medio ambiente</a>
              </h1>
            </div>
            |
            <div class="grg_container_item_menu_responsabilidad_social">
              <h1
                class="text-uppercase grg_text_extra_small grg_font_family_rb"
              >
                <a href="#universidades">Universidades</a>
              </h1>
            </div>
            |
            <div class="grg_container_item_menu_responsabilidad_social">
              <h1
                class="text-uppercase grg_text_extra_small grg_font_family_rb"
              >
                <a href="#fundaciones">Fundaciones</a>
              </h1>
            </div>
            |
            <div class="grg_container_item_menu_responsabilidad_social">
              <h1
                class="text-uppercase grg_text_extra_small grg_font_family_rb"
              >
                <a href="#colaboradores">Colaboradores</a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="grg_section_mensaje_director"
      style="background-color: #b1a090"
      id="bienvenidos"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
            <div class="">
              <p class="grg_text_medium">
                <span
                  class="
                    grg_font_outline
                    grg_first_letter
                    grg_font_family_reb
                    grg_title_bigger
                    text-uppercase
                  "
                  style="
                    --color_solid: #000;
                    --color_outline: #000;
                    --pos_x: 0.05em;
                    --pos_y: 0.05em;
                    line-height: 0.5;
                  "
                  >E</span
                >n nuestro país existe una extraordinaria riqueza humana, sin
                embargo, también existen desigualdades que hacen necesario el
                involucramiento de todos los actores de la sociedad para
                contribuir a construir un México mejor. Tanto empresas, como
                gobiernos y sociedad civil organizada, somos co-responsables en
                la construcción de un México en donde cada quien pueda
                desarrollar todo su potencial con base en sus necesidades e
                intereses en armonía con la naturaleza, en una sana convivencia
                y con un respeto al estado de derecho.
              </p>
              <p class="grg_text_medium grg_font_family_rr">
                Es por eso que,
                <strong
                  >desde hace casi dos décadas, en GRG<span
                    style="font-size: 10px"
                    >®</span
                  >
                  desarrollamos iniciativas tanto filantrópicas, como de
                  responsabilidad social y sostenibilidad, que buscan construir
                  una sociedad más justa e incluyente a la par de preservar los
                  recursos naturales.</strong
                >
              </p>
              <p class="grg_text_medium grg_font_family_rr">
                Si bien es una tarea ambiciosa, estamos convencidos que no hay
                esfuerzo menor cuando se trata de actuar en el presente mirando
                hacia el futuro de la humanidad, incluso pensando en aquellos
                que aún no han nacido.
              </p>
              <p class="grg_text_medium grg_font_family_rr">
                Los invito a conocer nuestras iniciativas responsables y
                sostenibles.
              </p>
              <p class="grg_text_medium grg_font_family_rr">
                <strong>Les envío un cordial saludo.</strong>
              </p>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="grg_container_director_all">
              <div class="grg_container_img_director_rs">
                <div
                  class="grg_container_img_director_rs_aro position-relative"
                >
                  <img
                    class="img-fluid grg_img_director_rs"
                    src="@/assets/img/responsabilidad_social/director_rs.webp"
                    alt=""
                  />
                </div>
              </div>
              <div class="grg_container_info_director_rs">
                <h1 class="grg_font_family_rb grg_text_extra_big text-white">
                  Gustavo Pérez Berlanga
                </h1>
                <h1
                  class="grg_font_family_rr grg_text_big text-white"
                  style="line-height: 1; font-style: italic"
                >
                  Director de Responsabilidad Social<br />Grupo Restaurantero
                  Gigante
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_nuestras_acciones" id="nuestras_acciones">
      <div class="container-fluid">
        <div class="row position-relative">
          <div class="col-sm-12">
            <div class="grg_container_titulo text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_reb grg_title_medium
                  text-uppercase
                "
                style="
                  --color_solid: #000;
                  --color_outline: #000;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Nuestras Acciones
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="grg_section_timeline_rs grg_evitar_seleccion">
      <div
        class="grg_container_timeline_horizontal_rs swiper-container"
        id="grg_container_timeline_horizontal_rs"
      >
        <button type="button" class="btn btn-default btn-sm prev-slide">
          <img
            width="30px"
            src="@/assets/img/responsabilidad_social/prev.svg"
            alt=""
          />
        </button>
        <button type="button" class="btn btn-default btn-sm next-slide">
          <img
            width="30px"
            src="@/assets/img/responsabilidad_social/next.webp"
            alt=""
          />
        </button>
        <div
          class="grg_container_timeline_horizontal_rs_comodin swiper-wrapper"
        >
          <div
            v-for="accion of acciones"
            :key="accion.id"
            class="grg_container_periodo swiper-slide"
          >
            <div class="grg_container_periodo_interno">
              <div class="grg_container_texto_periodo">
                <div
                  class="grg_font_family_rb grg_title_small"
                  style="color: #606261"
                >
                  {{ accion.fecha }}
                </div>
                <div
                  class="grg_font_family_rb grg_text_extra_small"
                  style="color: #606261"
                  v-html="accion.descripcion"
                ></div>
              </div>
            </div>
            <div class="grg_container_icono_timelie_rs">
              <img
                class="img-fluid"
                :width="accion.width"
                :src="obtenerImagenAcciones(accion.icono)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <section
      class="grg_section_objetivos_desarrollo_sostenible position-relative"
      id="onu"
    >
      <div class="grg_transparencia_desarrollo_sostenible"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="grg_container_colaboradores">
              <div class="grg_container_titulo text-center">
                <h1
                  class="
                    grg_font_outline grg_font_family_reb grg_title_medium
                    text-uppercase
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                  "
                >
                  Objetivos de <br />desarrollo sostenible
                </h1>
              </div>
              <div class="grg_container_descripcion_colaboradores text-center">
                <p class="grg_text_medium grg_font_family_re text-white">
                  Gracias a todas nuestras iniciativas hemos logrado contribuir
                  con 16 de los 17 Objetivos de Desarrollo Sostenible de la ONU.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_objetivos_desarrollo_sostenible_asstes">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 grg_container_imagen_objetivos">
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_1.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_2.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_3.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_4.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_5.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_6.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_7.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_8.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_9.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_11.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_12.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_13.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_14.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_15.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_16.webp"
                alt=""
              />
            </div>
            <div class="grg_container_item_objetivo">
              <img
                class="img-fluid grg_img_icono_objetivos_sostenibles_rs"
                src="@/assets/img/responsabilidad_social/objetivo_17.webp"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center grg_container_imagen_onu">
            <img
              class="img-fluid grg_img_onu_objetivos"
              src="@/assets/img/responsabilidad_social/objetivos_onu.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <section
      class="grg_section_proyectos_productivos"
      id="proyectos_productivos"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="grg_container_titulo text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_reb grg_title_medium
                  text-uppercase
                "
                style="
                  --color_solid: #fff;
                  --color_outline: #fff;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Proyectos productivos
              </h1>
            </div>
            <div
              class="text-center grg_container_descricion_proyectos_productivos"
            >
              <div>
                <p class="grg_text_medium grg_font_family_rr text-white">
                  Desde 2003, GRG<span style="font-size: 10px">®</span> ha
                  integrado a pequeños productores a su cadena de valor en un
                  modelo de comercio justo, sostenible y socialmente
                  responsable. Esta iniciativa ha beneficiado a miles de
                  personas en todo el país y ha sido reconocida en México,
                  Estados Unidos, Centro y Sudamérica, Europa y Asia. Dentro de
                  las iniciativas se encuentran: Café Cañada Azul, Mole Doña
                  Yolanda, Miel Amuzga, entre otros.
                </p>
              </div>
            </div>
          </div>
          <div class="grg_slider_proyectos_productivos">
            <div
              id="slider_proyectos_productivos"
              class="carousel slide"
              data-ride="carousel"
              data-interval="800000"
            >
              <div class="carousel-inner">
                <!-- <div class="carousel-item active w-100">
                                    <img class="d-block w-100 grg_img_ficha_vacia"  src="@/assets/img/responsabilidad_social/ficha.png" alt="First slide">
                                    <div class="carousel-caption d-md-block grg_carouser_caption">
                                        <h1 class="grg_font_outline grg_font_family_reb grg_title_extra_small text-uppercase grg_title_slider_proyectos_productivos" style="--color_solid:#fff; --color_outline:#fff; --pos_x: 0.05em; --pos_y: -0.05em;">Intro</h1>
                                        <div class="grg_container_descripcion_slider_proyectos_productivos">
                                            <h1 class="grg_text_medium">Hoy en día contamos con más de 250 restaurantes en México en formatos de: Restaurante Cafetería: Toks, Fast Food: Panda Express, Casual Dining: Beer Factory and Food, Fine Casual: Shake Shack y Restaurante de Especialidad: El Farolito.<br>Representando con orgullo marcas mexicanas y extranjeras que comparten nuestros valores y compromiso con la sociedad.</h1>
                                            
                                        </div>
                                        
                                    </div>

                                    <div style="position: absolute;
                                        top: 50%;
                                        transform: translateY(-50%); right:0;">
                                        <div class="grg_container_outline_slider">
                                            <img class="grg_img_slider_interna" src="@/assets/img/responsabilidad_social/slide_interno.jpg">
                                        </div>
                                        
                                    </div>
                                </div> -->

                <div class="carousel-item active w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      Café Cañada Azul
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">
                        Productores Orgánicos del Tacaná SPR de RL
                      </h1>
                      <h1 class="grg_text_medium">
                        - 2016, Cacahoatán, Chiapas
                      </h1>
                      <h1 class="grg_text_medium">- 1650 beneficiarios</h1>
                      <p class="grg_text_extra_small">
                        Este proyecto productivo se sumó a la iniciativa del
                        grupo en 2016. El café es cultivado por más de 150
                        pequeños productores de la Reserva de la Biosfera Volcán
                        Tacaná en Chiapas. Además de ser un proyecto
                        sustentable, es de comercio justo (sin intermediarios) y
                        socialmente responsable. Ayudamos a que la cadena de
                        distribución sea más eficiente comprando de manera
                        directa a los productores.
                      </p>
                      <div class="grg_container_iconos_redes_slider">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="instagram.com/productorestacana/?hl=en"
                            >
                              <img
                                class="grg_img_redes_slider"
                                src="@/assets/img/responsabilidad_social/instagram.webp"
                                alt="First slide"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/Productores-Tacan%C3%A1-100903298428081/"
                            >
                              <img
                                class="grg_img_redes_slider"
                                src="@/assets/img/responsabilidad_social/facebook.webp"
                                alt="First slide"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://twitter.com/productorest"
                            >
                              <img
                                class="grg_img_redes_slider"
                                src="@/assets/img/responsabilidad_social/twitter.webp"
                                alt="First slide"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >
                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/slide_interno_cafe.webp"
                      />
                    </div>
                  </div>
                </div>

                <div class="carousel-item w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      MIEL AMUZGA
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">
                        Miel de la Montaña Amuzga SPR de RI
                      </h1>
                      <h1 class="grg_text_medium">
                        - 2003, Xochistlahuaca, Guerrero
                      </h1>
                      <h1 class="grg_text_medium">- 950 beneficiarios</h1>
                      <p class="grg_text_extra_small">
                        Gracias a este proyecto, en el que participan más de 40
                        apicultores nativos de la región, la migración de la
                        comunidad ha disminuido, asegurando la permanencia de
                        las familias y sus costumbres en un esquema de comercio
                        justo, libre de trabajo infantil, sustentable y
                        socialmente responsable.
                      </p>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >
                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/slide_interno_miel.webp"
                      />
                    </div>
                  </div>
                </div>

                <div class="carousel-item w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      MOLE DOÑA YOLANDA Y<br />
                      GRANOLA MAZAHUA
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">
                        Productos Nutricionales de la Rosa SC de RL de CV
                      </h1>
                      <h1 class="grg_text_medium">
                        - 2005, San Felipe del Progreso, Edo. De México
                      </h1>
                      <h1 class="grg_text_medium">- 254 beneficiarios</h1>
                      <p class="grg_text_extra_small">
                        La comunidad de indígenas mazahuas de San Felipe del
                        Progreso participan en la creación de dos de nuestros
                        proyectos productivos: Mole Doña Yolanda y Granola
                        Mazahua. Ambos proyectos han mejorado exponencialmente
                        la calidad de vida de la comunidad. El mole es elaborado
                        con la receta tradicional de Doña Yolanda, habitante de
                        la localidad.
                      </p>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >
                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/slide_interno_granola.webp"
                      />
                    </div>
                  </div>
                </div>

                <div class="carousel-item w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      CHOCOLATE SIERRA MORENA Y MOLE NEGRO TIERRA DE MOLES
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">
                        Mena Mujeres Envasadoras de Alimentos SA de CV
                      </h1>
                      <h1 class="grg_text_medium">
                        - 2008, Ayoquezco de Aldama, Oaxaca
                      </h1>
                      <h1 class="grg_text_medium">- 99 beneficiarios</h1>
                      <p class="grg_text_extra_small">
                        El chocolate Sierra Morena y el mole negro artesanal son hechos por una empresa de mujeres emprendedoras de Oaxaca. La empresa se distingue por su elaboración artesanal de chocolate y productos derivados.
                      </p>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >
                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/foto3.webp"
                      />
                    </div>
                  </div>
                </div>

                <div class="carousel-item w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      NUEZ CARAMELIZADA<br />LEYENDAS DEL CORAZÓN
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">
                        Meléndez Bernal Hugo Jahve
                      </h1>
                     <!-- <h1 class="grg_text_medium">- 2016, Tacubaya, CDMX</h1>
                      <h1 class="grg_text_medium">- 209 beneficiarios</h1>
                      <p class="grg_text_extra_small">
                        La nuez caramelizada es hecha por una comunidad en la
                        zona de Tacubaya. Este producto artesanal ayuda a más de
                        34 familias a tener acceso a mejores oportunidades.
                      </p>-->
                      <!-- Actualización por petición del cliente realizado por ICS  05/04/2022 -->
                      <h1 class="grg_text_medium">- 2016, Tláhuac, CDMX</h1>
                      <h2 class="grg_text_medium">- 85 beneficiarios</h2>
                      <p class="grg_text_extra_small">
                          Proyecto socialmente responsable, comprometido con el empoderamiento de hombres y mujeres artesanas, a través de la generación de empleos dignos y el fomento del comercio justo mediante la producción y comercialización de recetas artesanales mexicanas.
                      </p>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >
                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/slide_interno_nuez.webp"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="carousel-item w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      Vainilla y Especias S.A de C.V
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">
                        Vainilla y Especias S.A de C.V
                      </h1>
                      <h1 class="grg_text_medium">
                        - 2019, Gutiérrez Zamora, Veracruz
                      </h1>
                      <h1 class="grg_text_medium">- 819 beneficiarios</h1>
                      <p class="grg_text_extra_small">
                        Este producto es creado por Vanilla and Spices, una
                        empresa para la que el recurso humano es parte
                        fundamental de su crecimiento. La organización apoya
                        constantemente a todos sus productores y empleados,
                        cuenta con diversas certificaciones y distintivos, entre
                        ellos ESR.
                      </p>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >
                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/slide_interno_vainilla.webp"
                      />
                    </div>
                  </div>
                </div> -->

                <div class="carousel-item w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      OBLEAS SHAKE SHACK
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">SEMVID SPR DE RL</h1>
                      <h1 class="grg_text_medium">- 2022,Tulyehualco, CDMX</h1>
                      <h1 class="grg_text_medium">- 60 beneficiarios</h1>
                      <p class="grg_text_extra_small">
                        Empresa familiar, especializada en sembrando, cosechar y transformar amaranto en productos gourmet de manera artesanal.
                      </p>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >
                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/slide_interno_obleas.webp"
                      />
                    </div>
                  </div>
                </div>

                <!-- Inserción de nuevos sliders 05/04/2022 -->
                <!-- <div class="carousel-item w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      NOPAL EL FAROLITO
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">-2011, Milpa Alta, CDMX</h1>
                      <h1 class="grg_text_medium">-135 beneficiarios </h1>
                      <p class="grg_text_extra_small">
                        Productores agrícolas que, a través de la aplicación de diversas 
                        técnicas sustentables, buscan desarrollar productos de alta calidad 
                        y generación de empleos dignos dentro de su comunidad.  
                      </p>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >
                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/slide_interno_nopal.jpg"
                      />
                    </div>
                  </div>
                </div> -->
                <!-- Comentado por modificaciones en el texto 12/04/2022 -->
                <div class="carousel-item w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      HUEVO DE GALLINA <br> LIBRE DE JAULA <!-- (RT MÉRIDA) -->
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">Kikibá</h1>
                      <h1 class="grg_text_medium">-Mérida, Yucatán</h1>
                      <h1 class="grg_text_medium">- 286 beneficiarios</h1>
                      <p class="grg_text_extra_small">
                        En colaboración con Heifer México, se logró integrar a 56 mujeres 
                        emprendedoras del proyecto “Mujeres Emprendedoras Innovando Comunidades 
                        Sostenibles”, quienes lograron comercializar sus productos a través de Kikibá, 
                        una marca colectiva de productoras de huevo de gallina libre de jaula y estrés que 
                        trabajan para obtener alimentos de calidad y generar fuentes de ingreso sostenibles.   
                      </p>
                    </div>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >

                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/slide_interno_huevo.webp"
                      />
                    </div>
                  </div>
                </div>


                <div class="carousel-item w-100">
                  <img
                    class="d-block w-100 grg_img_ficha_vacia"
                    src="@/assets/img/responsabilidad_social/ficha.png"
                    alt="First slide"
                  />
                  <div class="carousel-caption d-md-block grg_carouser_caption">
                    <h1
                      class="
                        grg_font_outline
                        grg_font_family_reb
                        grg_title_extra_small
                        grg_title_slider_proyectos_productivos
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      Mermelada de fresa
                    </h1>
                    <div
                      class="
                        grg_container_descripcion_slider_proyectos_productivos
                      "
                    >
                      <h1 class="grg_text_medium">Santa Rosa</h1>
                      <h1 class="grg_text_medium">- Salamanca, Gto.</h1>
                      <h1 class="grg_text_medium">- 119 beneficiarios</h1>
                      <p class="grg_text_extra_small">
                        Proyecto que apoya a pequeños productores de Guanajuato, zona fresera por excelencia en México, aprovechando las fresas de la región y poniendo su mayor esfuerzo en cada detalle elaborando esta mermelada única. Orgullosos integrantes de la familia de Proyectos Productivos desde 2022.    
                      </p>
                    </div>

                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: 0;
                    "
                  >
                    <div class="grg_container_outline_slider">
                      <img
                        class="grg_img_slider_interna"
                        src="@/assets/img/responsabilidad_social/mermelada.webp"
                      />
                    </div>
                  </div>
                </div>
                <!-- -------------------------------------- -->

              </div>
              <a
                class="carousel-control-prev"
                href="#slider_proyectos_productivos"
                role="button"
                data-slide="prev"
              >
                <img
                  class="grg_elemento_flechas_slider"
                  width="50%"
                  src="@/assets/img/responsabilidad_social/prev.svg"
                  alt=""
                />
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#slider_proyectos_productivos"
                role="button"
                data-slide="next"
              >
                <img
                  class="grg_elemento_flechas_slider"
                  width="50%"
                  src="@/assets/img/responsabilidad_social/next.svg"
                  alt=""
                />
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="grg_section_medio_ambiente position-relative"
      id="medio_ambiente"
    >
      <div class="grg_transparencia_medio_ambiente"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="grg_container_medio_ambiente">
              <div class="grg_container_titulo text-center">
                <h1
                  class="
                    grg_font_outline grg_font_family_reb grg_title_medium
                    text-uppercase
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                  "
                >
                  Medio Ambiente
                </h1>
              </div>
              <div class="grg_container_descripcion_medio_ambiente text-center">
                <p class="grg_text_medium grg_font_family_re text-white">
                  Desarrollamos planes y programas de responsabilidad social
                  para fomentar la protección del medio ambiente. Hemos
                  trabajado en planes de separación de residuos, reciclado y
                  recuperación de materiales. Todo esto con el fin de fomentar
                  una nueva cultura en nuestros colaboradores y aliados.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_programas_medio_ambiente position-relative">
      <div class="container-fluid">
        <div class="row text-center">
          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs">
              <h1
                class="grg_font_family_reb grg_text_extra_big text-uppercase"
                style="color: #626462"
              >
                Reciclado de residuos
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/aceite_vegetal.svg"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs">
              <div>
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  Impacto:
                </h1>
              </div>
              <div class="text-left grg_container_texto_descripcion">
                <h1 class="grg_font_family_rr grg_text_small">
                  - Residuos reciclados : <strong>161,328.78 kg</strong>
                </h1>
                <h1 class="grg_font_family_rr grg_text_small">
                  - Beneficio Ambiental :
                  <strong
                    >161 metros cúbicos de espacio en relleno sanitario.</strong
                  >
                </h1>
                <h1 class="grg_font_family_rr grg_text_small">
                  - Las tarimas de madera y el cartón reciclado equivalen a
                  <strong>664 árboles salvados.</strong>
                </h1>
                <h1 class="grg_font_family_rr grg_text_small">
                  - Con las 11.7 toneladas de plástico reciclado se evita la
                  emisión de
                  <strong>38 toneladas de dióxido de carbono.</strong>
                </h1>
                <h1 class="grg_font_family_rb grg_text_extra_small">
                  Fomentamos de manera constante la práctica de reciclaje. En
                  CEDIS y el Comisariato se transforman materiales ya utilizados
                  en nuevos objetos, evitando que aumente el nivel de rellenos
                  sanitarios.
                </h1>
              </div>
            </div>
          </div>
          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs">
              <h1
                class="grg_font_family_reb grg_text_extra_big text-uppercase"
                style="color: #626462"
              >
                Aceite vegetal usado (AVU)
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/reciclado.webp"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs">
              <div>
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  Impacto:
                </h1>
              </div>
              <div class="text-left grg_container_texto_descripcion">
                <h1 class="grg_font_family_rr grg_text_small">
                  - Se han recolectado para su transformación en biocombustible
                  un total de: <strong>119,343.2 litros de AVU.</strong>
                </h1>
                <h1 class="grg_font_family_rr grg_text_small">
                  - Beneficio ambiental: Evitar la potencial contaminación de
                  más de 118,375 Millones de litros de agua.
                </h1>
                <h1 class="grg_font_family_rr grg_text_small">
                  - Se fabrican más de
                  <strong
                    >66,900 litros de biodiesel, combustible limpio</strong
                  >
                  con los que se puede recorrer más de 1,759,287 km. Dejando de
                  emitir 173.8 toneladas de dióxido de carbono.
                </h1>

                <h1 class="grg_font_family_rb grg_text_extra_small">
                  Hemos creado una cultura enfocada a la responsabilidad
                  ambiental respecto a la separación de residuos de manejo
                  especial.
                </h1>
              </div>
            </div>
          </div>
          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs">
              <h1
                class="grg_font_family_reb grg_text_extra_big text-uppercase"
                style="color: #626462"
              >
                Reciclado de Tóner
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/toner.svg"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs">
              <div>
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  Impacto:
                </h1>
              </div>
              <div class="text-left grg_container_texto_descripcion">
                <h1 class="grg_font_family_rr grg_text_small">
                  - Beneficio ambiental: <strong>480 kg de plásticos</strong>
                </h1>
                <h1 class="grg_font_family_rr grg_text_small">
                  - Ahorro de
                  <strong>972 kw/h de energía eléctrica de producción</strong>
                </h1>
                <h1 class="grg_font_family_rr grg_text_small">
                  - Ahorro de <strong>18,720 litros de agua.</strong>
                </h1>
                <h1 class="grg_font_family_rr grg_text_small">
                  -
                  <strong>1.4 toneladas de dióxido de carbono</strong> evitados.
                </h1>

                <h1 class="grg_font_family_rb grg_text_extra_small">
                Se han reciclado para remanufacturación 319 pzas. de tóner vacíos.
                Contribuimos en disminuir, evitar y prevenir el desuso de materiales potencialmente peligrosos a través de las 4 Rs: Reducir, Reutilizar, Reciclar y Recuperar.
                </h1>
              </div>
            </div>
          </div>
          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs">
              <h1
                class="grg_font_family_reb grg_text_extra_big text-uppercase"
                style="color: #626462"
              >
                Bosque TOKS
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/bosque_toks.svg"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs">
              <div>
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  Impacto:
                </h1>
              </div>
              <div class="text-left grg_container_texto_descripcion">
                <h1 class="grg_font_family_rr grg_text_small">
                  - Desde 2017 se
                  <strong>han logrado recuperar más de 1200</strong> árboles
                  navideños.
                </h1>
                <h1 class="grg_font_family_rb grg_text_extra_small">
                  Con la iniciativa de rentar árboles vivos en maceta para
                  nuestros restaurantes de la zona conurbada (CDMX, Estado de
                  México y Morelos), hemos sembrado el bosque Toks con los
                  árboles que sobreviven a la temporada decembrina después de
                  ser sometidos a un proceso de recuperación con un éxito del
                  65%.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="grg_section_universidades position-relative"
      id="universidades"
    >
      <div class="grg_transparencia_universidades"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="grg_container_universidades">
              <div class="grg_container_titulo text-center">
                <h1
                  class="
                    grg_font_outline grg_font_family_reb grg_title_medium
                    text-uppercase
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                  "
                >
                  Universidades
                </h1>
              </div>
              <div class="grg_container_descripcion_universidades text-center">
                <p class="grg_text_medium grg_font_family_re text-white">
                  Con el fin de motivar, aprovechar el talento de los jóvenes y
                  generar un cambio positivo en nuestra sociedad, desde el 2008
                  creamos una alianza con universidades nacionales,
                  internacionales, públicas y privadas. Gracias a estas
                  alianzas, los estudiantes tienen la posibilidad de participar
                  de manera directa con el ambiente empresarial e interactuar
                  con una empresa del sector restaurantero.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_programas_universidades position-relative">
      <div class="container-fluid">
        <div class="row text-center justify-content-center">
          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs">
              <h1
                class="grg_font_family_reb grg_text_extra_big text-uppercase"
                style="color: #626462"
              >
                GRG<span style="font-size: 10px">®</span> Saludable
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs_universidades">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/saludable.webp"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs_universidades">
              <div class="grg_container_fecha_descripcion_universidades">
                <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2010</h1> -->
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  446 COLABORADORES BENEFICIADOS
                </h1>
                <br />
              </div>
              <div class="text-left grg_container_texto_descripcion">
                <h1 class="grg_font_family_rr grg_text_small">
                  En alianza con diferentes universidades de nuestro país, a
                  partir de 2010, se proporciona a nuestros colaboradores
                  información sobre la importancia de la alimentación con el fin
                  de mantener una vida saludable a través de atención
                  nutricional.
                </h1>
              </div>
            </div>
          </div>
          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs">
              <h1
                class="grg_font_family_reb grg_text_extra_big text-uppercase"
                style="color: #626462"
              >
                Cátedra Corporativa GRG<span style="font-size: 10px">®</span>
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs_universidades">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/catedra.svg"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs_universidades">
              <div class="grg_container_fecha_descripcion_universidades">
                <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2014</h1> -->
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  18 GENERACIONES,<br />MÁS DE 500 ALUMNOS
                </h1>
              </div>
              <div class="text-left grg_container_texto_descripcion">
                <h1 class="grg_font_family_rr grg_text_small">
                  Una asignatura impartida a alumnos de la UNAM desde 2014 y del
                  IPN desde 2019. Los alumnos aprenden las principales áreas
                  funcionales de una empresa: la ética empresarial, la
                  sustentabilidad y la responsabilidad social.
                </h1>
              </div>
            </div>
          </div>

          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs">
              <h1
                class="
                  grg_font_family_reb grg_text_extra_big
                  text-uppercase
                  p-2
                "
                style="color: #626462"
              >
                Día
                <img
                  class="img-fluid"
                  style="width: 80px; margin-top: -10px"
                  src="@/assets/img/responsabilidad_social/dia_grg2.svg"
                  alt=""
                />
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs_universidades">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/dia_grg.svg"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs_universidades">
              <div class="grg_container_fecha_descripcion_universidades">
                <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2018</h1> -->
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  9463 alumnos, docentes y aliados participantes
                </h1>
              </div>
              <div class="text-left grg_container_texto_descripcion">
                <h1 class="grg_font_family_rr grg_text_small">
                  Desde 2018 sumamos fuerzas con diferentes instituciones de
                  educación superior para compartir con sus estudiantes un poco
                  de nuestra experiencia, aportándoles conocimiento general y
                  ayudándoles a entender cómo funciona una empresa.
                </h1>
              </div>
            </div>
          </div>

          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs">
              <h1
                class="
                  grg_font_family_reb grg_text_extra_big
                  text-uppercase
                  p-2
                "
                style="color: #626462"
              >
                SESIÓN DE RESPONSABILIDAD SOCIAL
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs_universidades">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/grg_universidades.svg"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs_universidades">
              <div class="grg_container_fecha_descripcion_universidades">
                <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2018</h1> -->
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  130 alumnos, profesores y administrativos  participantes
                </h1>
              </div>
              <div class="text-left grg_container_texto_descripcion">
                <h1 class="grg_font_family_rr grg_text_small">
                  Participaron 130 alumnos, profesores y administrativos de la Escuela Superior de Contabilidad y Administración del IPN.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="grg_section_fundaciones"
      style="background-color: #b1a090"
      id="fundaciones"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="grg_container_titulo text-center">
              <img
                class="img-fluid"
                width="100px"
                src="@/assets/img/responsabilidad_social/fundaciones.svg"
                alt=""
              />
              <h1
                class="
                  grg_font_outline grg_font_family_reb grg_title_medium
                  text-uppercase
                "
                style="
                  --color_solid: #000;
                  --color_outline: #000;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                  padding: 1rem 0;
                "
              >
                Fundaciones
              </h1>
              <div class="grg_container_descripcion_fundaciones">
                <h1 class="grg_text_big grg_font_family_rr">
                  Desde 2009 contribuimos con distintas organizaciones de la
                  sociedad civil con donativos en especie, monetarios y a través
                  de campañas de recaudación en nuestras sucursales. Además de
                  sumarnos a su causa, en total hemos apoyado a más de 20
                  fundaciones.
                </h1>
              </div>
            </div>
          </div>

          <div class="col-md-12 h-100 grg_container_cruz">
            <div class="grg_container_fundaciones">
              <div class="container">
                <div class="row mt-4">
                  <div
                    class="
                      col-sm-12 col-md-3
                      d-flex
                      justify-content-center
                      align-items-center
                      text-center
                    "
                  >
                    <div class="grg_container_titulo_programas_rs">
                      <h1
                        class="grg_font_family_reb grg_text_big text-uppercase"
                      >
                        JUGUETOKS
                      </h1>
                      <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2009</h1> -->
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="grg_container_datos_fundaciones">
                      <h1 class="grg_font_family_rr grg_text_small">
                        Es una iniciativa que desde hace más de 10 años lleva
                        juguetes y alegría a los niños, que, por diferentes
                        motivos, no tienen la oportunidad de recibir regalos el
                        Día de Reyes.
                      </h1>
                      <!-- <h1 class="grg_font_family_rr grg_text_small">Beneficiarios directos de fundaciones y comunidades en situación vulnerable.</h1> -->
                    </div>
                  </div>
                  <div
                    class="
                      col-sm-12 col-md-3 col-xl-2
                      d-flex
                      justify-content-center
                      align-items-center
                      text-center
                    "
                  >
                    <div class="grg_container_titulo_programas_rs">
                      <h1 class="grg_font_family_rb grg_text_big">
                        5,281 niños beneficiados
                      </h1>
                      <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2009</h1> -->
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div
                    class="
                      col-sm-12 col-md-3
                      d-flex
                      justify-content-center
                      align-items-center
                      text-center
                    "
                  >
                    <div class="grg_container_titulo_programas_rs">
                      <h1
                        class="grg_font_family_reb grg_text_big text-uppercase"
                      >
                        Donativo fundaciones<br />shake shack
                      </h1>
                      <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2009</h1> -->
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="grg_container_datos_fundaciones">
                      <h1 class="grg_font_family_rr grg_text_small">
                        Además de donar el 1% de las ventas de agua embotellada
                        a Fundación Cántaro Azul, Shake Shack apoya a
                        fundaciones cercanas a sus sucursales donándoles el 5%
                        de la venta de alguno de sus concretes (producto
                        estrella de la marca).
                      </h1>
                      <!-- <h1 class="grg_font_family_rr grg_text_small">Beneficiarios directos de fundaciones y comunidades en situación vulnerable.</h1> -->
                    </div>
                  </div>
                  <div
                    class="
                      col-sm-12 col-md-3 col-xl-2
                      d-flex
                      justify-content-center
                      align-items-center
                      text-center
                    "
                  >
                    <div class="grg_container_titulo_programas_rs">
                      <h1 class="grg_font_family_rb grg_text_big">
                        573 personas beneficiadas
                      </h1>
                      <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2009</h1> -->
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div
                    class="
                      col-sm-12 col-md-3
                      d-flex
                      justify-content-center
                      align-items-center
                      text-center
                    "
                  >
                    <div class="grg_container_titulo_programas_rs">
                      <h1
                        class="grg_font_family_reb grg_text_big text-uppercase"
                      >
                        Programa<br />"Al rescate"
                      </h1>
                      <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2009</h1> -->
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="grg_container_datos_fundaciones">
                      <h1 class="grg_font_family_rr grg_text_small">
                        Panda Express y Shake Shack, en alianza con Banco de
                        Alimentos, rescatan los alimentos preparados que no se
                        consumieron en los restaurantes y que estén en
                        condiciones óptimas de higiene y temperatura para que a
                        través del Programa “Al rescate” lleguen a distintas
                        comunidades e instituciones en situación vulnerable.
                      </h1>
                      <!-- <h1 class="grg_font_family_rr grg_text_small">Beneficiarios directos de fundaciones y comunidades en situación vulnerable.</h1> -->
                    </div>
                  </div>
                  <div
                    class="
                      col-sm-12 col-md-3 col-xl-2
                      d-flex
                      justify-content-center
                      align-items-center
                      text-center
                    "
                  >
                    <div class="grg_container_titulo_programas_rs">
                      <h1 class="grg_font_family_rb grg_text_big">
                        5 fundaciones beneficiadas y 1 banco de alimentos
                      </h1>
                      <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2009</h1> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="grg_section_colaboradores position-relative"
      id="colaboradores"
    >
      <div class="grg_transparencia_colaboradores"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="grg_container_colaboradores">
              <div class="grg_container_titulo text-center">
                <h1
                  class="
                    grg_font_outline grg_font_family_reb grg_title_medium
                    text-uppercase
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                  "
                >
                  Colaboradores
                </h1>
              </div>
              <div class="grg_container_descripcion_colaboradores text-center">
                <p class="grg_text_medium grg_font_family_re text-white">
                  Nuestros colaboradores son muy importantes para nosotros, por
                  eso, les brindamos apoyo con actividades humanitarias,
                  educativas y de salud a través de diferentes programas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_programas_colaboradores position-relative">
      <div class="container-fluid">
        <div class="row text-center justify-content-center">
          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs_colaboradores">
              <h1
                class="grg_font_family_reb grg_text_extra_big text-uppercase"
                style="color: #626462"
              >
                Programa útiles escolares
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs_colaboradores">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/utiles.svg"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs_colaboradores">
              <div class="grg_container_fecha_descripcion_colaboradores">
                <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2005</h1> -->
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  33,173 estudiantes beneficiados
                </h1>
              </div>
              <div
                class="text-left grg_container_texto_descripcion_colaboradores"
              >
                <h1 class="grg_font_family_rr grg_text_small">
                  Desde 2005 y en alianza con Fundación Gigante contribuimos con
                  la educación y la economía familiar entregando paquetes con
                  útiles escolares para hijos(as) de colaboradores de niveles
                  operativos.
                </h1>
                <h1
                  class="
                    text-center
                    grg_font_family_rb grg_text_small
                    text-uppercase
                  "
                >
                  Dirigido a :
                </h1>
                <p class="text-center grg_font_family_rb grg_text_extra_small">
                  Estudiantes, hijos(as) de colaboradores.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs_colaboradores">
              <h1
                class="grg_font_family_reb grg_text_extra_big text-uppercase"
                style="color: #626462"
              >
                Apoyo a colaboradores fondo verde
              </h1>
            </div>
            <div class="grg_container_icono_programa_rs_colaboradores">
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/fondo_verde.svg"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs_colaboradores">
              <div class="grg_container_fecha_descripcion_colaboradores">
                <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2009</h1> -->
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  767 personas beneficiadas
                </h1>
              </div>
              <div
                class="text-left grg_container_texto_descripcion_colaboradores"
              >
                <h1 class="grg_font_family_rr grg_text_small">
                  Fondo Verde nace en 2009 con la finalidad de contribuir a
                  mejorar la calidad de colaboradores y familiares. El apoyo se
                  proporciona cuando alguien se encuentra en condiciones
                  extremas de salud y no puede ser atendido. Los recursos se
                  obtienen de la venta de residuos (PET, fierro, tóners, etc.).
                </h1>
                <h1
                  class="
                    text-center
                    grg_font_family_rb grg_text_small
                    text-uppercase
                  "
                >
                  Dirigido a :
                </h1>
                <p class="text-center grg_font_family_rb grg_text_extra_small">
                  Colaboradores y familiares, cónyuge e hijos(as) y padres.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 h-100">
            <div class="grg_container_titulo_programas_rs_colaboradores">
              <h1
                class="grg_font_family_reb grg_text_extra_big text-uppercase"
                style="color: #626462"
              >
                Programa lentes
              </h1>
            </div>
            <div
              class="
                grg_container_icono_programa_rs_colaboradores
                grg_container_icono_programa_rs_colaboradores_unico
              "
            >
              <img
                class="img-fluid grg_img_icono_programa_rs"
                src="@/assets/img/responsabilidad_social/lentes.svg"
                alt=""
              />
            </div>
            <div class="grg_container_descripcion_programa_rs_colaboradores">
              <div class="grg_container_fecha_descripcion_colaboradores">
                <!-- <h1 class="grg_font_family_rb grg_text_bigger text-uppercase">2011</h1> -->
                <h1 class="grg_font_family_rb grg_text_medium text-uppercase">
                  9,024 personas beneficiadas
                </h1>
              </div>
              <div
                class="text-left grg_container_texto_descripcion_colaboradores"
              >
                <h1 class="grg_font_family_rr grg_text_small">
                  En alianza de Fundación Gigante y Ver Bien Para Aprender
                  Mejor, desde 2011, se brinda atención optométrica y se donan
                  lentes a colaboradores y familiares que padecen miopía,
                  hipermetropía y astigmatismo.
                </h1>
                <h1
                  class="
                    text-center
                    grg_font_family_rb grg_text_small
                    text-uppercase
                  "
                >
                  Dirigido a :
                </h1>
                <p class="text-center grg_font_family_rb grg_text_extra_small">
                  Colaboradores y familiares directos, cónyuge e hijos(as).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Slider from "@/components/Slider.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "ResponsabilidadSocial",
  components: {
    Slider,
  },
  data() {
    return {
      itemsMenuRS: [],
      timeline: [],
      timeline_text: [],
      sliders: [
        {
          id: 1,
          posicion: 0,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/rslider1.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 2,
          posicion: 1,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/rslider2.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 3,
          posicion: 2,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/rslider4.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 4,
          posicion: 3,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/rslider5.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 5,
          posicion: 4,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/rslider6.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 6,
          posicion: 5,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/rslider7.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
      ],
      acciones: [
        {
          id: 1,
          fecha: "2003",
          icono: "periodo1.svg",
          width: "50px",
          descripcion: "- #ProyectoProductivo: Miel Amuzga",
        },
        {
          id: 2,
          fecha: "2004",
          icono: "periodo2.svg",
          width: "50px",
          descripcion:
            '- Campaña "Cambiando vidas"<br>(Cruz Roja, Ciudad del Carmen, Campeche)',
        },
        {
          id: 3,
          fecha: "2005",
          icono: "periodo3.svg",
          width: "30px",
          descripcion:
            "- Programa de útiles escolares con Fundación Gigante<br>- #ProyectoProductivo: Mole Doña Yolanda y Granola Mazahua",
        },
        {
          id: 4,
          fecha: "2006",
          icono: "periodo4.svg",
          width: "50px",
          descripcion:
            "- Distintivo de Empresa Socialmente Responsable (1er año)<br>- Participación en Pacto Mundial de la ONU<br>"+
            "- #ProyectoProductivo: Mermelada Santa Rosa",
        },
        {
          id: 5,
          fecha: "2007",
          icono: "periodo5.svg",
          width: "40px",
          descripcion: "- Bosque Toks",
        },
        {
          id: 6,
          fecha: "2008",
          icono: "periodo6.svg",
          width: "40px",
          descripcion:
            "- Caso de estudio IPADE (Proyectos Productivos)<br>- #ProyectoProductivo: Chocolate Sierra Morena y <br>Mole Negro Tierra de Moles",
        },
        {
          id: 7,
          fecha: "2009",
          icono: "periodo7.svg",
          width: "45px",
          descripcion:
            "- Fondo Verde<br>- Jugetoks<br>- Alianzas con fundaciones",
        },
        {
          id: 8,
          fecha: "2010",
          icono: "periodo8.svg",
          width: "50px",
          descripcion:
            '- Miembros del grupo Asesores en Sustentabilidad en la Cadena de Suministro, Pacto Mundial de la ONU<br>- Toks toma la Presidencia de Pacto Mundial México<br>- GRG<span style="font-size:10px">®</span> Saludable',
        },
        {
          id: 9,
          fecha: "2011",
          icono: "periodo9.svg",
          width: "40px",
          descripcion:
            "- Reconocimiento SEP: empresa comprometida con la educación<br>de sus colaboradores<br>- Programa de lentes para colaboradores con Fundación Gigante",
        },
        {
          id: 10,
          fecha: "2012",
          icono: "periodo10.svg",
          width: "45px",
          descripcion:
            "- Desarrollo de programas de prevención del delito y <br>reinserción social (Reintegra)<br>- Premio: Intervención social más responsable del mundo<br>otorgado por la ONU",
        },
        {
          id: 11,
          fecha: "2013",
          icono: "periodo11.svg",
          width: "35px",
          descripcion: "- Certificación Earthcheck (Parque Delta)",
        },
        {
          id: 12,
          fecha: "2014",
          icono: "periodo12.svg",
          width: "45px",
          descripcion:
            "- Premio UNAM: Compromiso a favor de la educación en México<br>- #ProyectoProductivo: Esferas Navideñas<br>- #ProyectoProductivo: Regalo 10 de mayo <br>"+
            "- #ProyectoProductivo: Árboles navideños con raíz",
        },
        {
          id: 13,
          fecha: "2015",
          icono: "periodo13.svg",
          width: "40px",
          descripcion:
            "- Campaña de cultura de legalidad, alianza con MUCD <br>- Miembros de la Junta Directiva del Pacto Mundial de la ONU",
        },
        {
          id: 14,
          fecha: "2016",
          icono: "periodo14.svg",
          width: "40px",
          descripcion:
            "- Premio: Liderazgo en Empoderamiento de Mujeres, otorgado<br>por la ONU<br>- #ProyectoProductivo: Café Cañada Azul<br>- #ProyectoProductivo: Nuez caramelizada Leyendas del Corazón<br>- Taller de serigrafía para Fundación Reintegra AC",
        },
        {
          id: 15,
          fecha: "2018",
          icono: "periodo15.svg",
          width: "50px",
          descripcion:
            "- Presidencia de la comisión de responsabilidad social: ICC, capítulo México<br>- Miembros del grupo de trabajo de pérdida y desperdicio de alimentos de CCA<br>- #ProyectosProductivos: Abeja de Peluche Kaabi",
        },
        {
          id: 16,
          fecha: "2019",
          icono: "periodo16.svg",
          width: "45px",
          descripcion:
            '- Presidencia de la iniciativa "Alliance for Integrity" de GIZ<br>- Caso de éxito publicado por la ONU:  Guía de Empresas y Derechos Humanos<br>- Distintivo S a 11 unidades Toks por parte del Gobierno Federal<br>- Mejor práctica en integridad empresarial: "Mentoria a Pymes"<br>otorgado por MCCI<br>- Programa "Al rescate": Panda Express y Shake Shack<br>- Apoyo a fundaciones por venta de alimentos seleccionados (Shake Shack)<br>'+
            '#ProyectoProductivo: Extracto de vainilla y obleas',
        },
        {
          id: 17,
          fecha: "2020",
          icono: "periodo17.svg",
          width: "45px",
          descripcion:
            '- Ganador de la mejor práctica: "Revalorizando la salud <br>de nuestros colaboradores"<br>- Premio Spira: Progreso corporativo por la compasión<br>hacia los animales<br>- Boscares: Fomento a la cultura forestal',
        },
        {
          id: 18,
          fecha: "2021",
          icono: "periodo18.svg",
          width: "45px",
          descripcion:
            '- Ganador de la mejor práctica: "Proyecto de café sostenible y responsable utilizando tecnología Blockchain"<br>- Mención especial de la mejor práctica: "Proyectos Productivos en tiempos de COVID-19".<br>- Insignia "Inclusión Social"<br>- #ProyectoProductivo: Huevo de gallina libre en las sucursales Toks de Mérida, Yucatán.<br>- Alianza con la universidad de Notre Dame:  Desarrollo del sistema de trazabilidad del Café Tacaná, a través de BlockChain.<br>- Programa "Al rescate":  Toks.<br>'+
            '- #ProyectoProductivo : Nopal sustentable',
        },
        {
          id: 19,
          fecha: "2022",
          icono: "periodo19.webp",
          width: "45px",
          descripcion:
            '- Análisis Ambiental de materiales reciclables en alianza con CINVESTAV.<br>- Análisis sobre ahorro de energía en comisariato Vallejo, en alianza con Veolia Environnement.<br>',
        },
      ],
      steps: [
        { dateLabel: "January 2017", title: "Gathering Information" },
        { dateLabel: "February 2017", title: "Planning" },
        { dateLabel: "March 2017", title: "Design" },
        { dateLabel: "April 2017", title: "Content Writing and Assembly" },
        { dateLabel: "May 2017", title: "Coding" },
        { dateLabel: "June 2017", title: "Testing, Review & Launch" },
        { dateLabel: "July 2017", title: "Maintenance" },
      ],
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
    activarSlider() {
      $("#grg_slider_nuestras_marcas").carousel();
      $("#slider_proyectos_productivos").carousel();
    },
    obtenerImagenAcciones(icono) {
      if (icono != "") {
        return require("@/assets/img/responsabilidad_social/" + icono);
      }
    },
    setEqualHeights(el) {
      var counter = 0;
      for (var i = 0; i < el.length; i++) {
        var singleHeight = el[i].offsetHeight;
        // console.log(singleHeight)

        if (counter < singleHeight) {
          counter = singleHeight;
        }
      }

      for (var i = 0; i < el.length; i++) {
        el[i].style.height = counter + "px";
      }
      $(".grg_container_time_line_rs").css("height", counter * 2.3 + "px");
    },
    moverSliderDerecha() {
      $(".grg_container_periodos").css("transform", "translateX(-500px)");
      // $('.grg_container_ficha_periodo:first').insertAfter('.grg_container_ficha_periodo:last');
    },
    moverSliderIzquierda() {
      // $('grg_container_ficha_periodo:last').insertBefore('grg_container_ficha_periodo:first');
      $(".grg_container_periodos").css("transform", "translateX(0px)");
    },
    moverSliderAcciones(column) {
      var swiper = new Swiper(".swiper-container", {
        width: 300,
        grabCursor: true,
        navigation: {
          nextEl: ".next-slide",
          prevEl: ".prev-slide",
        },
        breakpoints: {
          100: {
            slidesPerView: 1,
            width: 300,
          },
          900: {
            slidesPerView: 2,
            width: 700,
          },
          1000: {
            slidesPerView: 2.5,
            width: 800,
          },
          1100: {
            slidesPerView: 3,
            width: 850,
          },
          1200: {
            slidesPerView: 3.5,
            width: 950,
          },
          1300: {
            slidesPerView: 3.5,
            width: 1100,
          },
        },
      });
    },
    getDirection() {
      var windowWidth = window.innerWidth;
      var direction = window.innerWidth <= 760 ? "vertical" : "horizontal";

      return direction;
    },
    detectarScrollRS(evt, el) {
      // console.log(window.scrollY)
      var altura_section_principal = $(".grg_section_principal").outerHeight(
        true
      );
      var altura_header = $(".grg_modulo_header").outerHeight(true);
      if ($(window).scrollTop() >= altura_section_principal) {
        // console.log($(window).scrollTop()+','+altura_section_principal)
        $(".grg_section_menu_responsabilidad_social").addClass(
          "position-sticky menu_fixed_rs"
        );
        $(".grg_section_menu_responsabilidad_social").css(
          "top",
          altura_header + "px"
        );
      } else {
        $(".grg_section_menu_responsabilidad_social").removeClass(
          "position-sticky menu_fixed_rs"
        );
      }
    },
    cambioColorAutomatico() {
      // console.log("cambioColorAutomatico")
      var windowHeight = $(window).scrollTop() + 200;
      var array = $(".grg_container_items_menu_responsabilidad_social a");
      var arrayli = $(
        ".grg_container_items_menu_responsabilidad_social .grg_container_item_menu_responsabilidad_social"
      );
      var arrayHref = new Array();
      var arrayHrefTop = new Array();
      var i, h, j;
      for (i = 0; i < array.length; i++) {
        arrayHref.push(array[i].getAttribute("href"));
      }
      for (i = 0; i < array.length; i++) {
        var item = $(arrayHref[i]).offset();
        arrayHrefTop.push(item);
      }
      // console.log(arrayHref)
      // console.log(arrayHrefTop)
      for (i = 0; i < arrayHrefTop.length; i++) {
        if (windowHeight < arrayHrefTop[1].top) {
          // console.log("Estas en " + arrayHref[0])
          for (h = 0; h < arrayli.length; h++) {
            arrayli[h].classList.remove(
              "grg_container_item_menu_responsabilidad_social_active"
            );
          }
          arrayli[0].className +=
            " grg_container_item_menu_responsabilidad_social_active";
          i = arrayHrefTop.length;
        } else if ([i + 1] < [arrayHrefTop.length]) {
          if (
            windowHeight > arrayHrefTop[i].top &&
            windowHeight <= arrayHrefTop[i + 1].top
          ) {
            // console.log("Estas en " + arrayHref[i])
            for (h = 0; h < arrayli.length; h++) {
              arrayli[h].classList.remove(
                "grg_container_item_menu_responsabilidad_social_active"
              );
            }
            arrayli[i].className +=
              " grg_container_item_menu_responsabilidad_social_active";
            i = arrayHrefTop.length;
          }
        } else if (windowHeight > arrayHrefTop[arrayHrefTop.length - 1].top) {
          // console.log("Estas en " + arrayHref[arrayHrefTop.length - 1])
          for (h = 0; h < arrayli.length; h++) {
            arrayli[h].classList.remove(
              "grg_container_item_menu_responsabilidad_social_active"
            );
          }
          arrayli[array.length - 1].className +=
            " grg_container_item_menu_responsabilidad_social_active";
          i = arrayHrefTop.length;
        }
      }
    },
  },
  created() {
    this.grgFuncionChangeTransparencia(true);
    window.addEventListener("scroll", this.detectarScrollRS);
    window.addEventListener("scroll", this.cambioColorAutomatico);
  },
  mounted() {
    this.activarSlider();
    this.timeline = document.querySelectorAll(
      ".grg_container_periodos  .grg_container_ficha_periodo"
    );
    this.timeline_text = document.querySelectorAll(
      ".grg_container_periodos  .grg_container_periodo "
    );
    // console.log(this.timeline);
    // console.log(this.timeline_text);
    this.setEqualHeights(this.timeline_text);
    this.moverSliderAcciones(4);
  },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `GRG | Responsabilidad Social`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GrupoToks",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.row {
    margin: 0 -15px;
}
.btn.disabled,
.btn:disabled {
  opacity: 0;
}
section {
  scroll-margin-top: 7em;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */
.grg_section_nuestras_acciones {
  padding: 2rem 0rem;
  background-color: #fff;
  /* overflow: hidden; */
}
.swiper-wrapper {
  height: auto;
}
.prev-slide img {
  filter: brightness(0.5);
}
.next-slide img {
  filter: brightness(0.5);
}
.prev-slide {
  left: 0;
  position: absolute;
  z-index: 10;
  box-shadow: none;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  bottom: 325px;
}
.next-slide {
  right: 0;
  position: absolute;
  z-index: 10;
  box-shadow: none;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  bottom: 325px;
}
.grg_section_timeline_rs {
  position: relative;
  /* padding: 10px 0 10px 0; */
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */
.grg_section_proyectos_productivos {
  background-color: #2b2b2b;
  padding: 2rem 0rem;
}
.grg_slider_proyectos_productivos {
  padding: 0rem 0;
}
/*.grg_container_outline_slider {
   background-color: red; 
}*/
.grg_img_slider_interna {
  width: 650px;
}
.grg_title_slider_proyectos_productivos {
  padding: 0 0 2rem;
}
.grg_section_proyectos_productivos .grg_carouser_caption {
  right: auto;
  top: 52%;
  transform: translateY(-50%);
  width: 55%;
  bottom: 0;
  padding: 0;
  text-align: left;
  left: 10%;
}
.carousel-control-prev {
  opacity: 1;
  width: 5%;
}
.carousel-control-next {
  opacity: 1;
  width: 5%;
}
.grg_container_descripcion_slider_proyectos_productivos {
  background-color: #4f514e;
  padding: 5%;
}
/* .grg_container_iconos_redes_slider {
  /* background-color: red;
} */
.grg_container_iconos_redes_slider ul {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  list-style: none;
}
.grg_container_iconos_redes_slider ul li {
  padding-right: 1rem;
}
.grg_img_redes_slider {
  width: 30px;
}
.grg_container_descricion_proyectos_productivos {
  display: flex;
  justify-content: center;
  align-items: center;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivos de desarrollo sostenible */
.grg_section_objetivos_desarrollo_sostenible {
  background-image: url("../assets/img/responsabilidad_social/objetivos_desarrollo.webp");
  background-size: cover;
}
.grg_container_imagen_objetivos {
  padding: 1rem 0 1.6rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.grg_container_item_objetivo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.6rem 0.3rem 0rem 0.3rem;
  padding: 0 0px;
}
.grg_img_icono_objetivos_sostenibles_rs {
  width: 135px;
}
.grg_img_onu_objetivos {
  width: 500px;
}
.grg_container_imagen_onu {
  padding: 1rem 0.5rem 2rem;
}
.grg_transparencia_desarrollo_sostenible {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivos de desarrollo sostenible */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas colaboradores */
.grg_section_programas_colaboradores {
  padding: 2rem 0rem;
}
.grg_container_icono_programa_rs_colaboradores {
  min-height: 140px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.grg_container_icono_programa_rs_colaboradores_unico {
  /* background-color: pink; */
  min-height: 80px;
}
.grg_container_texto_descripcion_colaboradores {
  padding: 1.5rem 0;
}
.grg_container_texto_descripcion_colaboradores h1:first-child {
  padding: 0rem 0rem 2rem;
}
/* .grg_container_fecha_descripcion_colaboradores { */
  /* padding:  0 5rem; */
/* } */
.grg_container_titulo_programas_rs_colaboradores {
  padding: 3rem 0 0;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas colaboradores */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion colaboradores */
.grg_section_colaboradores {
  background-image: url("../assets/img/responsabilidad_social/colaboradores.webp");
  background-size: cover;
}
.grg_transparencia_colaboradores {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.grg_container_colaboradores {
  padding: 3.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion colaboradores */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion fundaciones */
.grg_section_fundaciones {
  padding: 2rem 0rem;
}
.grg_container_fundaciones {
  padding: 1.5rem 0rem;
}
.grg_container_descripcion_fundaciones {
  padding: 0 0 1rem;
}
.grg_container_datos_fundaciones h1 {
  /* margin: 1rem 0; */
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion fundaciones */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas universidades */
.grg_section_universidades {
  background-image: url("../assets/img/responsabilidad_social/libros_universidades.webp");
  background-size: cover;
}
.grg_section_programas_universidades {
  padding: 2rem 0rem;
}
.grg_container_icono_programa_rs_universidades {
  min-height: 110px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.grg_container_universidades {
  padding: 3.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas universidades */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas medio ambiente */
.grg_section_programas_medio_ambiente {
  padding: 2rem 0rem;
}
.grg_container_icono_programa_rs {
  min-height: 160px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.grg_img_icono_programa_rs {
  width: 100px;
}
.grg_container_texto_descripcion h1:last-child {
  padding: 1rem 0rem 2.5rem;
  line-height: 1.3;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas medio ambiente */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_medio_ambiente */
.grg_section_medio_ambiente {
  background-image: url("../assets/img/responsabilidad_social/papel_reciclado.webp");
  background-size: cover;
}
.grg_container_medio_ambiente {
  padding: 3.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grg_transparencia_medio_ambiente {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.grg_transparencia_universidades {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_medio_ambiente */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion menu responsabilidad social */
.grg_container_items_menu_responsabilidad_social {
  padding: 0.5rem 0;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.grg_container_item_menu_responsabilidad_social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.3rem;
  padding: 0 5px;
}
.grg_container_item_menu_responsabilidad_social_active {
  background-color: #bf203a;
  color: white;
}
.grg_container_item_menu_responsabilidad_social_active a {
  color: white !important;
}
/* .grg_container_item_menu_responsabilidad_social:nth-child(4) a{
    color: white;
} */
.grg_container_items_menu_responsabilidad_social h1 {
  margin: 0;
}
.grg_container_item_menu_responsabilidad_social a {
  color: black;
}
.menu_fixed_rs {
  width: 100%;
  background-color: #fff;
  z-index: 15;
  box-shadow: inset 0px 0 7px -4px #000;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion menu responsabilidad social */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion mensaje director */
.grg_section_mensaje_director {
  padding: 2rem 0rem;
}
.grg_container_img_director_rs {
  display: flex;
  justify-content: center;
}
.grg_container_img_director_rs_aro:before {
  content: "";
  position: absolute;
  width: 105%;
  height: 105%;
  border: 1px solid #fff;
  border-radius: 50%;
  bottom: -2.5%;
  right: 0;
}
.grg_img_director_rs {
  max-width: 250px;
}
.grg_container_info_director_rs {
  padding: 15px 0 0;
  text-align: center;
}
.grg_container_director_all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion mensaje director */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */
.grg_transparencia {
  filter: brightness(0.6);
}
.grg_container_trasparencia {
  background: linear-gradient(
    180deg,
    #0000 0%,
    #0000003b 24%,
    #000000e3 76%,
    #000,
    #000,
    #000
  );
  position: absolute;
  bottom: 0;
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s linear;
  z-index: 2;
}
.grg_container_principal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_container_titulo_pagina {
  position: absolute;
  margin: 1% 0;
  bottom: 0;
  z-index: 10;
}
.grg_container_titulo {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion en contrsuccion */
.grg_container_construccion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 0;
}
.grg_img_construccion {
  max-width: 60%;
}
.grg_container_leyenda_construccion {
  padding: 1rem 0;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion en contrsuccion */

@media (min-width: 1200px) {
  .grg_slider_proyectos_productivos {
    margin-top: calc(-5% + 15px);
  }
  .grg_container_texto_linetime {
    width: 180%;
    transform: translateX(-40%);
  }

  .grg_section_timeline_rs .grg_container_texto_periodo .grg_text_extra_small {
    font-size: 13px;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas universidades */

  .grg_container_fecha_descripcion_universidades {
    padding: 0 6rem;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas universidades */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion mensaje director */
  .grg_container_info_director_rs {
    /* position: absolute;
    right: -10px;
    bottom: 0;
    top: 50%;
    transform: translateY(-10%);
    text-align: left; */
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion mensaje director */
}
@media (min-width: 992px) {
  .grg_section_principal {
    margin-top: -95px;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */
  .grg_section_proyectos_productivos {
    padding: 4rem 0rem;
  }
  .grg_container_outline_slider:before {
    content: "";
    width: calc(100% + 1.1em);
    height: calc(100% + 2.2em);
    position: absolute;
    top: -1em;
    left: -0.9em;
    border: 0.1em solid #fff;
  }
  .grg_container_descricion_proyectos_productivos > div {
    width: 90%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivos de desarrollo sostenible */
  .grg_container_descripcion_objetivos_desarrollo_sostenible {
    width: 70%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivos de desarrollo sostenible */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion colaboradores */
  .grg_container_descripcion_colaboradores {
    width: 70%;
  }
  .grg_container_descripcion_programa_rs_colaboradores {
    padding: 0rem 4rem;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion colaboradores */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion fundaciones */
  .grg_section_fundaciones {
    padding: 3rem 4rem 3rem;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion fundaciones */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas universidades */

  .grg_container_descripcion_programa_rs_universidades {
    padding: 0rem 2rem;
  }
  .grg_container_descripcion_universidades {
    width: 80%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas universidades */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas medio ambiente */
  .grg_container_descripcion_programa_rs {
    padding: 1rem 2rem;
  }
  .grg_container_texto_descripcion h1:last-child {
    padding: 1.5rem 0rem 2.5rem;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion programas medio ambiente */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion mensaje director */
  .grg_section_mensaje_director {
    padding: 3rem 5rem 3rem;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion mensaje mdirector */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones_comentario */
  .grg_section_nuestras_acciones {
    padding: 4rem 0rem 0;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones_comentario */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_medio_ambiente */
  .grg_container_descripcion_medio_ambiente {
    width: 70%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_medio_ambiente */
}
@media (min-width: 768px) {
  .grg_container_timeline_horizontal_rs_comodin {
    display: flex;
    /* padding: 0 0 400px 0; */
    padding: 0 0 350px 0;
  }
  .grg_container_timeline_horizontal_rs {
    display: flex;
    position: relative;
    overflow: hidden;
    margin: 0 50px;
  }
  .grg_container_periodo {
    position: relative;
    margin: 0px 0px;
    display: flex;
  }
  .grg_container_periodo:nth-child(even) {
    align-items: flex-end;
  }
  .grg_container_periodo:nth-child(odd) {
    align-items: flex-start;
  }
  .grg_container_periodo_interno {
    width: 300px;
    position: relative;
  }
  .grg_container_periodo:nth-child(even) .grg_container_periodo_interno {
    margin-bottom: 70px;
  }
  .grg_container_periodo:nth-child(odd) .grg_container_periodo_interno {
    margin-top: 70px;
  }

  .grg_container_periodo:nth-child(even) {
  }
  .grg_container_periodo:nth-child(odd) {
    transform: translateY(100%);
  }
  .grg_container_periodo:nth-child(even):before {
    content: "";
    border: 2px #eed497 solid;
    position: absolute;
    width: 100%;
    bottom: -2px;
  }
  .grg_container_periodo:nth-child(odd):before {
    content: "";
    border: 2px #eed497 solid;
    position: absolute;
    width: 100%;
    top: -2px;
  }
  .grg_container_periodo:first-child:before {
    width: 50%;
    right: 0;
  }
  .grg_container_periodo:last-child:before {
    width: 50%;
    left: 0;
  }
  .grg_container_texto_periodo {
    width: 500px;
    transform: translatex(-20%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .grg_container_periodo:nth-child(even) .grg_container_icono_timelie_rs {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #eed497;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    bottom: -50px;
    left: calc(50% - 50px);
  }
  .grg_container_periodo:nth-child(odd) .grg_container_icono_timelie_rs {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #eed497;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    top: -50px;
    left: calc(50% - 50px);
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion fundaciones */
  .grg_container_descripcion_fundaciones {
    width: 90%;
  }
  /* .grg_container_fundaciones{
    min-height: 250px;
} */
  /* .grg_container_cruz:nth-child(odd){
    border-bottom: 2px #625952 solid;
}
.grg_container_cruz:nth-child(even){
    border-right: 2px #625952 solid;
    border-bottom: 2px #625952 solid;
}  .grg_container_cruz:last-child{
    border-bottom: 1px transparent solid;
}
.grg_container_cruz:nth-last-of-type(-n+2){
    border-bottom: 1px transparent solid;
} */
  .grg_container_fundaciones {
    /* padding: 1.5rem 0rem 0rem 0rem; */
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion fundaciones */
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */
  .grg_section_proyectos_productivos .grg_container_titulo {
    padding: 0 0 1rem;
  }
  .grg_title_slider_proyectos_productivos {
    padding: 0 0 0rem;
    text-align: center;
  }
  .grg_img_slider_interna {
    width: 100%;
  }
  .grg_section_proyectos_productivos .grg_carouser_caption {
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
    bottom: 0;
    padding: 0;
    text-align: left;
    left: 5%;
  }

  .grg_container_descripcion_slider_proyectos_productivos {
    padding: 2%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */
}
@media (max-width: 991.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */
  .grg_title_slider_proyectos_productivos {
    padding: 1rem 0;
  }
  .grg_section_proyectos_productivos .grg_carouser_caption {
    height: 100%;
    text-align: justify;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */
}
@media (max-width: 767.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */
  .prev-slide {
    top: 37px;
    bottom: auto;
    transform: translateY(0%);
  }
  .next-slide {
    top: 37px;
    bottom: auto;
    transform: translateY(0%);
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */

  .grg_container_timeline_horizontal_rs_comodin {
    display: flex;
    padding: 10px 0 50px 0;
  }
  .grg_container_timeline_horizontal_rs {
    display: flex;
    position: relative;
    overflow: hidden;
    margin: 0 10px;
  }
  .grg_container_periodo {
    position: relative;
    margin: 0px 0px;
    display: flex;
  }
  .grg_container_periodo:nth-child(even) {
    /* align-items: flex-end; */
  }
  .grg_container_periodo:nth-child(odd) {
    /* align-items: flex-start; */
  }
  .grg_container_periodo_interno {
    /* width: 300px; */
    position: relative;
    margin-top: 120px;
  }
  .grg_container_periodo:nth-child(even) .grg_container_periodo_interno {
    /* margin-bottom: 70px; */
  }
  .grg_container_periodo:nth-child(odd) .grg_container_periodo_interno {
    /* margin-top: 70px; */
  }

  .grg_container_periodo:nth-child(even) {
  }
  .grg_container_periodo:nth-child(odd) {
    /* transform: translateY(100%); */
  }
  .grg_container_periodo:nth-child(even):before {
    content: "";
    border: 2px #eed497 solid;
    position: absolute;
    width: 100%;
    top: 50px;
  }
  .grg_container_periodo:nth-child(odd):before {
    content: "";
    border: 2px #eed497 solid;
    position: absolute;
    width: 100%;
    top: 50px;
  }
  .grg_container_periodo:first-child:before {
    width: 50%;
    right: 0;
  }
  .grg_container_periodo:last-child:before {
    width: 50%;
    left: 0;
  }
  .grg_container_texto_periodo {
    width: 300px;
    /* transform: translatex(-25%); */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 30px;
  }
  .grg_container_periodo:nth-child(even) .grg_container_icono_timelie_rs {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #eed497;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* bottom: -50px; */
    left: calc(50% - 50px);
  }
  .grg_container_periodo:nth-child(odd) .grg_container_icono_timelie_rs {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #eed497;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* top: -50px; */
    left: calc(50% - 50px);
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion grg_section_nuestras_acciones */

  /* Estilo time line */
  .timeline {
    padding: 980px 0 0%;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */
  .grg_img_ficha_vacia,
  .grg_container_outline_slider,
  .grg_img_slider_interna {
    min-height: 400px;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion fundaciones */
  .grg_container_fundaciones {
    /* border-top: 2px #625952 solid; */
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion fundaciones */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivos de desarrollo sostenible */
  .grg_img_icono_objetivos_sostenibles_rs {
    width: 100px;
  }
  .grg_section_objetivos_desarrollo_sostenible
    .grg_container_titulo
    .grg_title_medium {
    font-size: calc(1rem + 4.5vw + -5px) !important;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivos de desarrollo sostenible */
}
@media (max-width: 575.98px) {
  .grg_container_items_menu_responsabilidad_social .grg_text_extra_small {
    font-size: calc(0.5rem + 0.5vw + 1px);
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */
  .grg_container_descripcion_slider_proyectos_productivos .grg_text_medium {
    font-size: calc(0.7rem + 0.4vw + 2px);
  }
  .grg_container_descripcion_slider_proyectos_productivos
    .grg_text_extra_small {
    font-size: calc(0.5rem + 0.5vw + 2px);
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion proyectos productivos */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivos de desarrollo sostenible */
  .grg_img_onu_objetivos {
    width: 100%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivos de desarrollo sostenible */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion en contrsuccion */
  .grg_img_construccion {
    max-width: 50%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion en contrsuccion */

  section {
    scroll-margin-top: 9em;
  }
}
</style>