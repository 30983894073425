var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grg_page_nuestras_marcas",staticStyle:{"background-color":"#2b2b2b"}},[_c('section',{staticClass:"grg_section_principal"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-0 grg_container_principal"},[_c('div',[_c('div',{staticClass:"grg_container_trasparencia"}),_c('Slider',{attrs:{"sliders":_vm.sliders}})],1),_vm._m(0)])])])]),_c('section',{staticClass:"grg_section_marcas"},_vm._l((_vm.marcas),function(marca){return _c('div',{key:marca.id,staticClass:"grg_container_ficha_marca position-relative",class:{ grg_utileria_flechas_abajo: marca.id != _vm.marcas.length },style:({ 'z-index': _vm.marcas.length + 1 - marca.id + '!important' }),attrs:{"id":marca.id_contenedor}},[_c('div',{attrs:{"id":marca.id_contenedor}},[_c('div',{staticClass:"container-fluid",attrs:{"data-aos":"fade-up","data-aos-anchor-placement":marca.animacion}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 text-center"},[_c('div',{staticClass:"grg_container_imagen_platillo h-100 grg_p_6",class:{ grg_p_6_1: _vm.grg_var_view_port_md }},[_c('div',{staticClass:"\n                    grg_container_imagen_platillo_comodin\n                    position-relative\n                  "},[_c('img',{staticClass:"rounded-circle img-fluid grg_img_principal_marca",style:({
                      'object-position': marca.position_imagen_marca,
                    }),attrs:{"src":_vm.obtenerImagenPrincipalMarca(marca.imagen_marca),"alt":""}}),_c('div',{staticClass:"grg_container_imagen_logo_marca",style:({ 'text-align': marca.position_logo_marca })},[_c('img',{attrs:{"width":marca.width_logo,"src":_vm.obtenerlogo(marca.logo_marca),"alt":""}})])])])]),_c('div',{staticClass:"col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7"},[_c('div',{staticClass:"grg_container_informacion_general"},[_c('div',{staticClass:"grg_container_titulo_descripcion"},[_c('h1',{staticClass:"\n                      grg_font_outline grg_font_family_rb grg_title_medium\n                      text-uppercase\n                    ",staticStyle:{"--color_solid":"#fff","--color_outline":"#fff","--pos_x":"0.05em","--pos_y":"-0.05em"}},[_vm._v(" "+_vm._s(marca.nombre_marca)+" ")]),_c('h1',{staticClass:"grg_text_big grg_font_family_rb",staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(marca.descripcion)+" ")])]),_c('div',{staticClass:"grg_container_datos_globales"},[(marca.categoria != '')?_c('div',{staticClass:"grg_container_datos"},[_c('div',{staticClass:"grg_container_icon_category"},[_c('img',{attrs:{"width":"40px","src":_vm.obtenerIconosDatos(
                            'icono_categoria.svg',
                            marca.id % 2
                          ),"alt":""}})]),_c('div',{staticClass:"grg_container_elemento_text_datos"},[_c('h1',{staticClass:"\n                          grg_text_extra_small\n                          text-uppercase\n                          grg_font_family_reb\n                        "},[_vm._v(" Categoría: "),_c('span',{staticClass:"grg_text_medium grg_font_family_rb p-2",staticStyle:{"color":"#fff","text-transform":"none"}},[_vm._v(_vm._s(marca.categoria))])])])]):_vm._e(),(marca.inicio_operaciones != '')?_c('div',{staticClass:"grg_container_datos"},[_c('div',{staticClass:"grg_container_icon_category"},[_c('img',{attrs:{"width":"40px","src":_vm.obtenerIconosDatos(
                            'icono_operaciones.svg',
                            marca.id % 2
                          ),"alt":""}})]),_c('div',{staticClass:"grg_container_elemento_text_datos"},[_c('h1',{staticClass:"\n                          grg_text_extra_small\n                          text-uppercase\n                          grg_font_family_reb\n                        "},[_vm._v(" Inicio de operaciones: "),_c('span',{staticClass:"grg_text_medium grg_font_family_rb p-2",staticStyle:{"color":"#fff","text-transform":"none"}},[_vm._v(_vm._s(marca.inicio_operaciones))])])])]):_vm._e(),(marca.portafolio_grg != '')?_c('div',{staticClass:"grg_container_datos"},[_c('div',{staticClass:"grg_container_icon_category"},[_c('img',{attrs:{"width":"40px","src":_vm.obtenerIconosDatos(
                            'icono_portafolio.svg',
                            marca.id % 2
                          ),"alt":""}})]),_c('div',{staticClass:"grg_container_elemento_text_datos"},[_c('h1',{staticClass:"\n                          grg_text_extra_small\n                          text-uppercase\n                          grg_font_family_reb\n                        "},[_vm._v(" Portafolio grg"),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("®")]),_vm._v(": "),_c('span',{staticClass:"grg_text_medium grg_font_family_rb p-2",staticStyle:{"color":"#fff","text-transform":"none"}},[_vm._v(_vm._s(marca.portafolio_grg))])])])]):_vm._e(),(marca.numero_colaboradores != '')?_c('div',{staticClass:"grg_container_datos"},[_c('div',{staticClass:"grg_container_icon_category"},[_c('img',{attrs:{"width":"40px","src":_vm.obtenerIconosDatos(
                            'icono_colaboradores.svg',
                            marca.id % 2
                          ),"alt":""}})]),_c('div',{staticClass:"grg_container_elemento_text_datos"},[_c('h1',{staticClass:"\n                          grg_text_extra_small\n                          text-uppercase\n                          grg_font_family_reb\n                        "},[_vm._v(" Número de colaboradores: "),_c('span',{staticClass:"grg_text_medium grg_font_family_rb p-2",staticStyle:{"color":"#fff","text-transform":"none"}},[_vm._v(_vm._s(marca.numero_colaboradores))])])])]):_vm._e(),(marca.presencia != '')?_c('div',{staticClass:"grg_container_datos"},[_c('div',{staticClass:"grg_container_icon_category"},[_c('img',{attrs:{"width":"40px","src":_vm.obtenerIconosDatos(
                            'icono_presencia.svg',
                            marca.id % 2
                          ),"alt":""}})]),_c('div',{staticClass:"grg_container_elemento_text_datos"},[_c('h1',{staticClass:"\n                          grg_text_extra_small\n                          text-uppercase\n                          grg_font_family_reb\n                        "},[_vm._v(" Presencia: "),_c('span',{staticClass:"grg_text_medium grg_font_family_rb p-2",staticStyle:{"color":"#fff","text-transform":"none"}},[_vm._v(_vm._s(marca.presencia))])])])]):_vm._e(),(marca.platillos_estrella != '')?_c('div',{staticClass:"grg_container_datos"},[_c('div',{staticClass:"grg_container_icon_category"},[_c('img',{attrs:{"width":"40px","src":_vm.obtenerIconosDatos(
                            'icono_signature.svg',
                            marca.id % 2
                          ),"alt":""}})]),_c('div',{staticClass:"grg_container_elemento_text_datos"},[_c('h1',{staticClass:"\n                          grg_text_extra_small\n                          text-uppercase\n                          grg_font_family_reb\n                        "},[_vm._v(" "+_vm._s(_vm.obtenertextoSingular(marca.platillos_estrella))+" "),_c('span',{staticClass:"grg_text_medium grg_font_family_rb p-2",staticStyle:{"color":"#fff","text-transform":"none"}},[_vm._v(_vm._s(marca.platillos_estrella))])])])]):_vm._e(),(marca.nuestras_cervezas != '')?_c('div',{staticClass:"grg_container_datos"},[_c('div',{staticClass:"grg_container_icon_category"},[_c('img',{attrs:{"width":"40px","src":_vm.obtenerIconosDatos(
                            'icono_cervezas.svg',
                            marca.id % 2
                          ),"alt":""}})]),_c('div',{staticClass:"grg_container_elemento_text_datos"},[_c('h1',{staticClass:"\n                          grg_text_extra_small\n                          text-uppercase\n                          grg_font_family_reb\n                        "},[_vm._v(" Nuestras cervezas: "),_c('span',{staticClass:"grg_text_medium grg_font_family_rb p-2",staticStyle:{"color":"#fff","text-transform":"none"}},[_vm._v(_vm._s(marca.nuestras_cervezas))])])])]):_vm._e(),_c('div',{staticClass:"grg_container_datos"},[_c('div',{staticClass:"grg_container_redes_sociales_global w-100"},[_vm._m(1,true),_c('div',{staticClass:"grg_container_redes_sociales"},[_c('a',{attrs:{"href":marca.link_instagram,"target":"_blank"}},[_c('img',{staticClass:"grg_img_icon_redes",attrs:{"src":require("../assets/img/iconos_marcas/red_instagram.svg"),"alt":""}})]),_c('a',{attrs:{"href":marca.link_facebook,"target":"_blank"}},[_c('img',{staticClass:"grg_img_icon_redes",attrs:{"src":require("../assets/img/iconos_marcas/red_facebook.svg"),"alt":""}})]),_c('a',{attrs:{"href":marca.link_twitter,"target":"_blank"}},[_c('img',{staticClass:"grg_img_icon_redes",attrs:{"src":require("../assets/img/iconos_marcas/red_twitter.svg"),"alt":""}})]),_c('a',{attrs:{"href":marca.link_web,"target":"_blank"}},[_c('img',{staticClass:"grg_img_icon_redes",attrs:{"src":require("../assets/img/iconos_marcas/red_web.svg"),"alt":""}})])])])])])])])])])])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grg_container_titulo_pagina grg_utileria_flechas_abajo"},[_c('h1',{staticClass:"\n                grg_font_outline grg_font_family_rb grg_title_big\n                text-uppercase\n              ",staticStyle:{"--color_solid":"#fff","--color_outline":"#fff","--pos_x":"0.05em","--pos_y":"-0.05em"}},[_vm._v(" Nuestras Marcas ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n                          grg_container_elemento_texto_siguenos\n                          position-relative\n                        "},[_c('h1',{staticClass:"grg_text_bigger grg_font_family_rb",staticStyle:{"color":"#fff","line-height":"1","margin":"0"},attrs:{"data-utileria":"flechas_derecha"}},[_vm._v(" ¡Síguenos en redes! ")])])}]

export { render, staticRenderFns }