<template>
  <div class="grg_page_contacto">
    <section class="grg_section_principal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-0 grg_container_principal">
            <div class="w-100">
              <img
                class="img-fluid"
                width="100%"
                src="@/assets/img/contacto/portada.webp"
                alt=""
              />
              <div class="grg_container_trasparencia"></div>
            </div>
            <div class="grg_container_titulo_pagina text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_big
                  text-uppercase
                "
                style="
                  --color_solid: #fff;
                  --color_outline: #fff;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Contáctanos
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="grg_section_construccion">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="grg_container_construccion">
                            <div>
                                <img class="img-fluid grg_img_construccion" src="@/assets/img/utilerias/imagen_construccion.png" alt="">
                            </div>
                            <div class="grg_container_leyenda_construccion">
                                <h1 class="grg_font_family_rb grg_text_big">Estamos construyendo esta sección.<br>¡Espérala pronto!</h1>
                            </div>
                            <div class="grg_container_hover">
                                <router-link to="/"><h2 class="grg_font_family_reb grg_text_medium grg_btn grg_btn_animacion" style="--color: #fff; --background_color:#BD203B" data-text="Regresar al inicio">Regresar al inicio</h2></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

    <section class="grg_section_mapa">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7524.660104868572!2d-99.2039899202597!3d19.44133165802068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d202056a8390a1%3A0xbd6a61a08a8b4075!2sCorporativo%20Grupo%20Gigante!5e0!3m2!1ses-419!2smx!4v1610080352287!5m2!1ses-419!2smx"
              width="1000"
              height="600"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_informacion" style="padding: 30px 0 50px 0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_medium
                  text-uppercase
                "
                style="
                  --color_solid: #000;
                  --color_outline: #000;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Información
              </h1>
            </div>
          </div>
          <div class="col-md-12">
            <div class="text-center p-2">
              <img
                width="25px"
                src="@/assets/img/utilerias/marcador.svg"
                alt=""
              />
              <h1
                class="grg_text_big grg_font_family_rb"
                style="color: #606060"
              >
                Av. Ejército Nacional 769, Granada,<br />Miguel Hidalgo,
                11520<br />Ciudad de México,<br />CDMX Edificio B piso 10
              </h1>
            </div>
            <div class="text-center p-2">
              <img
                width="35px"
                src="@/assets/img/utilerias/telefono.svg"
                alt=""
              />
              <h1
                class="grg_text_big grg_font_family_rb"
                style="color: #606060"
              >
                <a style="color: #606060" href="tel:5521225727"
                  >55 21 22 57 27</a
                >
              </h1>
            </div>
            <div class="text-center p-2">
              <img width="50px" src="@/assets/img/utilerias/sobre.svg" alt="" />
              <h1
                class="grg_text_big grg_font_family_rb"
                style="color: #606060"
              >
                <a style="color: #606060" href="mailto:contacto@grg.mx"
                  >contacto@grg.mx</a
                >
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Contacto",
  components: {},
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
  },
  created() {
    this.grgFuncionChangeTransparencia(true);
  },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `GRG | Contacto`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GrupoToks",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.row {
    margin: 0 -15px;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */
.grg_transparencia {
  filter: brightness(0.6);
}
.grg_container_trasparencia {
  background: linear-gradient(
    180deg,
    #0000 0%,
    #0000003b 24%,
    #000000e3 76%,
    #000,
    #000,
    #000
  );
  position: absolute;
  bottom: 0;
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s linear;
  z-index: 2;
}
.grg_container_principal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_container_titulo_pagina {
  position: absolute;
  margin: 1% 0;
  bottom: 0;
  z-index: 10;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */

.grg_container_construccion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 0;
}
.grg_img_construccion {
  max-width: 60%;
}
.grg_container_leyenda_construccion {
  padding: 1rem 0;
}
iframe {
  width: 100%;
}

@media (min-width: 1200px) {
}
@media (min-width: 992px) {
  .grg_section_principal {
    margin-top: -95px;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
  iframe {
    height: 400px;
  }
}
</style>